import { FC } from 'react';
import { ILongButton } from '../../interfaces/ui.interface';

const LongButton: FC<ILongButton> = ({ text, ...otherProps }) => {
	return (
		<button {...otherProps} className='w-full h-10 text-brand-white text-sm font-bold rounded-md flex justify-center items-center bg-brand-blue-primary hover:bg-brand-blue-primary-900 transition-all ease-out duration-500'>
			{text}
		</button>
	);
};

export default LongButton;
