import axios from 'axios';
import { useEffect, useState } from 'react';
import { URL } from '../api/api';
import { tokenState } from '../api/helper';
import { IResponseDashboard } from '../interfaces/responses.interface';
import { GetAPI } from './helperHook';

export const useDashboard = () => {
	const [isError, setError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [details, setDetails] = useState<IResponseDashboard | null>();

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine && tokenState) {
			GetAPI<IResponseDashboard>('dashboard/', tokenState)
				.then((resp) => {
					if (resp.data.status === 'success') {
						setError(false);
						setIsLoading(false);
						setDetails(resp.data);
					}
				})
				.catch((e) => {
					setError(true);
					setIsLoading(false);
					setDetails(null);
				});
		} else {
			setError(true);
		}

		return () => {};
	}, []);

	return { isError, isLoading, details } as const;
};
