import { Form, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { CsvWarning } from './CsvWarning';
import { FC, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import ShortButton from './ShortButton';
import { PostAPIPlain } from '../../hooks/helperHook';
import { tokenState, userDetails } from '../../api/helper';
import Notification from '../Notification';
import { useHistory } from 'react-router-dom';
import MiniLoader from '../MiniLoader';

interface IDrag {
  model_url: string;
  details: any;
  is_batch: any;
}

const DragUploadCSV: FC<IDrag> = ({ model_url, details, is_batch }) => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //upload functions
  const props = {
    name: 'file',
    accept: '.csv',
    action: '',
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file: any) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      return false;
    },
  };

  const onFinish2 = (values: any) => {
    const formData = new FormData();
    formData.append('model_url', model_url);
    formData.append('inference_params', values.csv.file);
    formData.append('is_batch', is_batch);
    formData.append('model_id', details.id);

    if (!values.csv.file.originFileObj) {
      //check if file is still present

      if (window.navigator.onLine) {
        setIsLoading(true);
        PostAPIPlain('inference/file', formData, tokenState)
          .then((resp) => {
            if (resp.data.status === 'success') {
              setIsLoading(false);
              Notification({
                type: 'success',
                message: 'Success!',
                description: 'Inference successful',
              });
              history.push('/predictions/batch/results', [
                resp.data,
                details.model_desc,
                details.public_id,
                details.model_loc,
                is_batch,
              ]);
            } else {
              setIsLoading(false);
              Notification({
                type: 'error',
                message: 'Error!',
                description: resp.data.message,
              });
            }
          })
          .catch((e) => {
            setIsLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description: e.response.data.message,
            });
          });
      } else {
        setIsLoading(false);
        Notification({
          type: 'error',
          message: 'Error!',
          description: 'Please check your internet connection!',
        });
      }
    }
  };

  const onFinishFailed2 = (errorInfo: {}) => {};
  return (
    <div className='w-full lg:w-11/12 flex flex-col justify-center'>
      <CsvWarning />
      <Form
        layout='vertical'
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish2}
        onFinishFailed={onFinishFailed2}
        className='w-full'
      >
        <Form.Item
          name='csv'
          className='mb-6'
          rules={[
            {
              required: true,
              message: 'Please select a file',
              type: 'object',
            },
          ]}
        >
          <Dragger {...props} multiple={false} maxCount={1}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined color='#1D4ED8' />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item>
          <div className='flex justify-center'>
            <ShortButton
              disabled={isLoading}
              text={isLoading ? <MiniLoader /> : 'Send Request'}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DragUploadCSV;
