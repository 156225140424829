import Layout from '../../components/Layout';
import { ICreateUser } from '../../interfaces/auth.interface';
import { Form, Input, Select } from 'antd';
import LongButton from '../../components/helper/LongButton';
import { RouteComponentProps } from 'react-router-dom';
import { useGetSingleUser } from '../../hooks/useGetSingleUser';
import ErrorMsg from '../../components/ErrorMsg';
import MainLoader from '../../components/MainLoader';
import { useContext } from 'react';
import { PermissionContext } from '../../contexts/permissions.context';

const { Option } = Select;

const EditUser = ({ match }: RouteComponentProps<{ id: string }>) => {
	const id = match.params.id;
	const { isError, isLoading, user } = useGetSingleUser(id);
	const { permission, isErrorP, isLoadingP } = useContext(PermissionContext);

	const onFinish = (values: ICreateUser) => {
		let creds = {
			firstname: values.firstname,
			lastname: values.lastname,
			email: values.email,
			page_permissions: values.page_permissions,
			inference_model_perm: values.inference_model_perm,
		};
	};

	const onFinishFailed = (errorInfo: {}) => {
		// console.log('Failed:', errorInfo);
	};

	const data = [
		{ key: '1', value: 'can edit' },
		{ key: '2', value: 'can save' },
		{ key: '3', value: 'can model' },
	];
	return (
		<Layout title='edit users'>
			{isError || isErrorP ? (
				<ErrorMsg />
			) : isLoading || isLoadingP ? (
				<MainLoader />
			) : (
				user && (
					<>
						<section className='flex flex-col justify-center items-center h-full w-full mb-7'>
							<div className='w-11/12 lg:w-[479px] 2xl:w-6/12 bg-brand-white card-shadow flex justify-center items-center py-8'>
								<Form
									layout='vertical'
									name='basic'
									initialValues={{
										firstname: user?.firstname,
										lastname: user?.lastname,
										email: user?.email,
									}}
									onFinish={onFinish}
									onFinishFailed={onFinishFailed}
									className='w-11/12'
								>
									<div className='flex justify-between'>
										<Form.Item
											label='First Name'
											name='firstname'
											className='mb-6 w-[48%]'
											rules={[
												{
													required: true,
													message:
														'Please input your firstname!',
													type: 'string',
												},
											]}
										>
											<Input
												className='w-full bg-transparent border h-10 px-3 rounded-md'
												placeholder='enter your firstname'
											/>
										</Form.Item>
										<Form.Item
											label='Last Name'
											name='lastname'
											className='mb-6 w-[48%]'
											rules={[
												{
													required: true,
													message:
														'Please input your lastname!',
													type: 'string',
												},
											]}
										>
											<Input
												className='w-full bg-transparent border h-10 px-3 rounded-md'
												placeholder='enter your lastname'
											/>
										</Form.Item>
									</div>
									<Form.Item
										label='Email'
										name='email'
										className='mb-6'
										rules={[
											{
												required: true,
												message:
													'Please input your email!',
												type: 'email',
												pattern:
													/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											},
										]}
									>
										<Input
											className='w-full bg-transparent border h-10 px-3 rounded-md'
											placeholder='enter your email'
											disabled
										/>
									</Form.Item>
									<Form.Item
										label='Permissions'
										name='page_permissions'
										className='mb-6'
										rules={[
											{
												required: true,
												message:
													'Please select permission(s)!',
												type: 'array',
											},
										]}
									>
										<Select
											mode='multiple'
											placeholder='select permission(s)'
											className='w-full'
											size='large'
											showArrow={true}
										>
											{permission &&
												permission!.map((data) => (
													<Option
														key={data.public_id}
														value={data.public_id}
													>
														{data.desc}
													</Option>
												))}
										</Select>
									</Form.Item>
									<Form.Item
										label='Models'
										name='inference_model_perm'
										className='mb-6'
										rules={[
											{
												required: true,
												message:
													'Please select model(s)!',
												type: 'array',
											},
										]}
									>
										<Select
											mode='multiple'
											placeholder='select model(s)'
											className='w-full'
											size='large'
											showArrow={true}
										>
											{data.map((data) => (
												<Option
													key={data.key}
													value={data.key}
												>
													{data.value}
												</Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item>
										<LongButton text='Save' />
									</Form.Item>
								</Form>
							</div>
						</section>
					</>
				)
			)}
		</Layout>
	);
};

export default EditUser;
