import Layout from '../../components/Layout';
import { PredictionTab } from '../../components/helper/PredictionTab';
import SelectModelForm from '../../components/predictions/SelectModelForm';
import { useState } from 'react';
import BatchPredictionForm from '../../components/predictions/BatchPredictionForm';

const PredictionsBatch = () => {
  const [model, setModel] = useState('');
  const [isModelSelected, setIsModelSelected] = useState(false);
  return (
    <Layout title='predictions'>
      <PredictionTab setIsModelSelected={setIsModelSelected} />
      {/* {!isModelSelected && ( */}
      <SelectModelForm
        setModel={setModel}
        setIsModelSelected={setIsModelSelected}
      />
      {/* // )} */}
      {/* {isModelSelected && (
				<BatchPredictionForm setIsModelSelected={setIsModelSelected} />
			)} */}
    </Layout>
  );
};

export default PredictionsBatch;
