import BadLinkIcon from '../assets/svg/BadlinkIcon';
import { Link } from 'react-router-dom';
import Header from '../components/onboarding/Header';

const BadRequest = () => {
	return (
		<div className='m-6'>
			<Header />
			<section className='flex justify-center items-center h-[90%]'>
				<div className='flex flex-col items-center'>
					<BadLinkIcon className='mb-[20px]' />
					<h1 className='text-[32px] text-brand-black-primary font-bold capitalize mb-[20px]'>
						invalid link!
					</h1>
					<p className='text-brand-black-secondary text-base font-normal'>
						Oops! We're sorry, we couldn't find the page you
						requested
					</p>
					<Link
						to='/'
						className='text-brand-blue-primary hover:text-brand-blue-primary-900 font-bold text-sm capitalize'
					>
						go home
					</Link>
				</div>
			</section>
		</div>
	);
};

export default BadRequest;
