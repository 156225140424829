import { FC } from 'react';
import BadLinkIcon from '../assets/svg/BadlinkIcon';
import { Link } from 'react-router-dom';

interface IBrokenLink {
	desc: string
}

const BrokenLink: FC<IBrokenLink> = ({desc}) => {
	return (
		<section className='flex justify-center items-center h-full'>
			<div className='flex flex-col items-center'>
				<BadLinkIcon className='mb-[20px]' />
				<h1 className='text-[32px] text-brand-black-primary font-bold capitalize mb-[20px]'>
					invalid link!
				</h1>
				<p className='text-brand-black-secondary text-base font-normal'>
					{desc}
				</p>
				<Link
					to='/'
					className='text-brand-blue-primary hover:text-brand-blue-primary-900 font-bold text-sm capitalize'
				>
					go home
				</Link>
			</div>
		</section>
	);
};

export default BrokenLink;
