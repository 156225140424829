import { notification } from 'antd';

interface INotif {
	type: string;
	message: string;
	description: string;
}

const Notification = ({ type, message, description }: INotif) => {
	(notification as any)[type]({ message, description });
};

export default Notification;
