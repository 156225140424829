import * as React from 'react';

function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				clipRule='evenodd'
				d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
				stroke='#141441'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 16v-4'
				stroke='#141441'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle cx={12} cy={8} r={1} fill='#141441' />
		</svg>
	);
}

export default InfoIcon;
