import CSVWarningIcon from '../../assets/svg/CSVWarningIcon';

export const CsvWarning = () => {
	return (
		<div className='flex w-full items-center justify-center  mb-10'>
			<CSVWarningIcon className='mr-6' />
			<span className='text-xs font-semibold text-brand-danger-900'>
				Upload file type must be in CSV format
			</span>
		</div>
	);
};
