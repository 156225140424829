import axios from 'axios';
import { useEffect, useState } from 'react';
import { URL } from '../api/api';
import { tokenState } from '../api/helper';
import { IResponseUser, IUser } from '../interfaces/responses.interface';
import { GetAPI } from './helperHook';

export const useGetAllUsers = () => {
	const [isError, setError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<IUser[] | null | [] >();

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine && tokenState) {
			GetAPI<IResponseUser>('user/', tokenState)
				.then((resp) => {
					if (resp.data.status === 'success') {
						setError(false);
						setIsLoading(false);
						setUsers(resp.data.data);
					}
				})
				.catch((e) => {
					setError(true);
					setIsLoading(false);
					setUsers(null);
				});
		} else {
			setError(true);
		}

		return () => {};
	}, []);

	return { isError, isLoading, users, setUsers } as const;
};
