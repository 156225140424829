import TableWrapper from '../helper/TableWrapper';
import { Table } from 'antd';
import { FC } from 'react';

interface IFeature {
  featureName: [];
  featureValues: any
}

const PredFeaturesList: FC<IFeature> = ({ featureName, featureValues }) => {

  const columns = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      key: 'feature',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const data = featureName.map((data, i) => ({
    key: i,
    feature: data,
    value: featureValues[data],
  }));

  return (
    <div>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ position: ['bottomCenter'], pageSize: 5 }}
          className=''
        />
      </TableWrapper>
    </div>
  );
};

export default PredFeaturesList;
