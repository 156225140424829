import Layout from '../../components/Layout';
import { PredictionTab } from '../../components/helper/PredictionTab';
import SelectModelForm from '../../components/predictions/SelectModelForm';
import { useState } from 'react';

const PredictionsSingle = () => {
  const [model, setModel] = useState('');
  const [isModelSelected, setIsModelSelected] = useState(false);

  return (
    <Layout title='predictions'>
      <PredictionTab setIsModelSelected={setIsModelSelected} />
      <SelectModelForm
        setModel={setModel}
        setIsModelSelected={setIsModelSelected}
        isBatch={true}
      />
    </Layout>
  );
};

export default PredictionsSingle;
