import Layout from '../../components/Layout';
import { SettingTab } from '../../components/helper/SettingTab';
import { Form, Input } from 'antd';
import ShortButton from '../../components/predictions/ShortButton';
import { IPassword } from '../../interfaces/auth.interface';
import styled from 'styled-components';

const FormInputTarget = styled.div`
  input {
    background: none;
  }
`;

const SettingsPassword = () => {
  const onFinish = (values: IPassword) => {
    let creds = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Layout title='settings'>
      <SettingTab />
      <Form
        layout='vertical'
        name='basic'
        initialValues={{
          firstname: 'nelson',
          lastname: 'madu',
          company_name: 'UBA Plc',
          email: 'n.madu@ubaplc.com',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='w-full'
      >
        <div className='w-full lg:w-[419px]'>
          <Form.Item
            label='Old Password'
            name='oldPassword'
            className='mb-4'
            rules={[
              {
                required: true,
                message: 'Please input your old password!',
              },
            ]}
          >
            <FormInputTarget>
              <Input.Password
                className='w-full  border h-10 px-3 rounded-md'
                placeholder='enter your password'
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </FormInputTarget>
          </Form.Item>
          <Form.Item
            label='New Password'
            name='newPassword'
            className='mb-4'
            rules={[
              {
                required: true,
                message:
                  'Password must be minumum of 8 characters, contains capital letter, small letter, number and special character',
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              },
            ]}
            hasFeedback
          >
            <FormInputTarget>
              <Input.Password
                className='w-full border h-10 px-3 rounded-md'
                placeholder='enter your password'
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </FormInputTarget>
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            className='mb-4'
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <FormInputTarget>
              <Input.Password
                className='w-full border h-10 px-3 rounded-md'
                placeholder='enter your password'
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </FormInputTarget>
          </Form.Item>
        </div>
        <Form.Item>
          <ShortButton text='Change Password' />
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default SettingsPassword;
