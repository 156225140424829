import { FC, LegacyRef, useContext, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import DangerIcon from '../assets/svg/DangerIcon';
import { AuthContext } from '../contexts/auth.context';
import ModalStatic from './ModalStatic';

const IdleTimeOut: FC = ({ children }) => {
  //set timeout for 5min and logout after 15 secs if there's no inactivity
  const [modal, setModal] = useState(false);
  const { logOut } = useContext(AuthContext);
  const sessionTimeOutRef = useRef<any>(null);

  const handleLogOut = () => {
    logOut();
    clearTimeout(sessionTimeOutRef.current!);
  };

  const handleClose = () => {
    setModal(false);
    clearTimeout(sessionTimeOutRef.current);
  };

  const onIdle = () => {
    setModal(true);
    sessionTimeOutRef.current = setTimeout(logOut, 15000);
  };

  return (
    <>
      <IdleTimer timeout={1000 * 60 * 5} onIdle={onIdle}>
        {children}
      </IdleTimer>
      <ModalStatic isOpen={modal} setModal={setModal}>
        <div className='flex flex-col justify-center items-center'>
          <div className='h-10 w-10 bg-brand-danger-400 rounded-full flex items-center justify-center mb-6'>
            <DangerIcon />
          </div>
          <h1 className='text-xl font-semibold text-brand-black-primary mb-3'>
            You have been idle for a while
          </h1>
          <p className='text-brand-black-secondary mb-3'>
            You will be logged out soon
          </p>
          <div className='flex'>
            <button
              onClick={handleClose}
              className='flex items-center justify-center w-[150px] h-10 mr-6 rounded-md px-3 py-2 bg-brand-black-secondary hover:bg-brand-black-primary text-brand-white font-semibold transition-all ease-out duration-500'
            >
              Keep me signed in
            </button>
            <button
              onClick={handleLogOut}
              className='flex items-center justify-center w-[150px] h-10 rounded-md px-3 py-2 bg-brand-danger-700 hover:bg-brand-danger-900 text-brand-white font-semibold transition-all ease-out duration-500'
            >
              Log out
            </button>
          </div>
        </div>
      </ModalStatic>
    </>
  );
};

export default IdleTimeOut;
