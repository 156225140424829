import * as React from 'react';

function HomeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				clipRule='evenodd'
				d='M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z'
				stroke='#5A5A7D'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9 22V12h6v10'
				stroke='#5A5A7D'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default HomeIcon;
