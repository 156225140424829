import axios from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { Model, URL } from '../api/api';
import { tokenState } from '../api/helper';
import { IGetModels } from '../interfaces/responses.interface';
import { AuthContext } from './auth.context';
import { IModel } from './types.interface';

const defaultModel: IModel = {
	model: null,
	isLoading: false,
	isError: false,
};

export const ModelContext = createContext<IModel>(defaultModel);

const ModelProvider: FC = ({ children }) => {
	const [model, setModel] = useState(defaultModel);
	const { isAuthenticated } = useContext(AuthContext);

	useEffect(() => {
		setModel({ ...model, isLoading: true });
		if (window.navigator.onLine) {
			axios
				.get<IGetModels>(`${URL}getmodels/`, {
					timeout: 15000,
					headers: {
						Authorization: `Bearer ${tokenState() && tokenState()}`,
					},
				})
				.then((resp) => {
					let data = resp.data as any;
					
					setModel({ ...model, isLoading: false });
					setModel({ ...model, isError: false });
					setModel({ ...model, model: data.data });

					// if (resp.status === 'success') {
					// setModel({ ...model, isLoading: false });
					// setModel({ ...model, isError: false });
					//  // setModel({...model, model: resp.data.models});
					//  setModel({ ...model, model: data.data });
					// } else {
					//  setModel({ ...model, isLoading: false });
					// setModel({ ...model, isError: true });
					//  setModel({ ...model, model: null });
					// }
				})
				.catch((e) => {
					setModel({ ...model, isLoading: false });
					setModel({ ...model, isError: true });
					setModel({ ...model, model: null });
				});
		} else {
			setModel({ ...model, isLoading: false });
			setModel({ ...model, isError: true });
			setModel({ ...model, model: null });
		}
		return () => {};
	}, [isAuthenticated()]);

	return (
		<ModelContext.Provider value={model}>{children}</ModelContext.Provider>
	);
};

export default ModelProvider;
