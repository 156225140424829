import { FC } from 'react';
import styled from 'styled-components';

const TableWrap = styled.section`
	.ant-table-thead > tr > th {
		background: #e9eefc;
	}
	.ant-pagination-item-active {
		background: #1d4ed8;
		border: none;
	}
	.ant-pagination-item-active a {
		color: #ffffff;
	}
	.ant-table-tbody > tr > td {
		border-bottom: 1px solid #dbe3df;
	}
	.ant-table {
		background: none !important;
	}
	@media (max-width: 640px) {
		.respo-table {
			width: 100%;
			white-space: nowrap;
			position: relative;
			overflow-x: scroll;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
		}
	}
`;

const TableWrapper: FC = ({ children }) => {
	return (
		<TableWrap>
			<div className='respo-table'>{children}</div>
		</TableWrap>
	);
};

export default TableWrapper;
