import { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DragUploadCSV from './DragUploadCSV';
import PredictedColumns from './PredictedColumns';
import queryString from 'querystring';
import { useGetModelDetails } from '../../hooks/useGetModelDetails';
import ErrorMsg from '../ErrorMsg';
import MainLoader from '../MainLoader';
import { RouteComponentProps } from 'react-router-dom';
import { PredictionResultTab } from '../PredictionResultTab';

interface IForm {
	setIsModelSelected: (selected: boolean) => void;
}

const BatchPredictionForm = ({ match }: RouteComponentProps<{ id: string }>) => {
	const [features, setFeatures] = useState<string[] | null>();
	const [fieldDetails, setFieldDetails] = useState<any>();
	const id = match?.params.id;
	const { details, isError, isLoading } = useGetModelDetails(id);

	useEffect(() => {
		details && setFeatures([...Object.keys(details.all_feature_kwarg)]);
		details && setFieldDetails(details.all_feature_kwarg);
		return () => {};
	}, [details]);

	return (
		<Layout title='Predictions'>
			{isError ? (
				<ErrorMsg />
			) : isLoading ? (
				<MainLoader />
			) : (
				details && (
					<div className='mb-7'>
						<Link to='/predictions/batch'
							className='text-sm font-bold inline-block text-brand-blue-primary hover:text-brand-blue-primary-900 border-b border-brand-blue-primary mb-8'
						>
							Change Model
						</Link>
						<PredictionResultTab modelId={id}/>
						<section className='flex flex-col lg:flex-row lg:items-start'>
							<div className='w-full mb-8 lg:w-[50%] lg:mr-8 lg:mb-0'>
								<div className='flex mb-7'>
									<div className='h-9 w-9 mr-5 bg-brand-blue-primary-400 rounded-full flex items-center justify-center'>
										<h3 className='text-brand-blue-primary text-lg font-bold mb-0'>
											1
										</h3>
									</div>
									<section>
										<h3 className='text-brand-black-primary text-lg font-bold'>
											Model -{' '}
											<span className='text-sm text-brand-black-secondary'>
											{details.model_desc}
											</span>
										</h3>
										<p className='text-xs font-semibold text-brand-black-secondary w-10/12'>
											This model expects the uploaded CSV
											to contain the following columns and
											data type
										</p>
									</section>
								</div>
								<PredictedColumns
									features={features}
									fieldDetails={fieldDetails}
								/>
							</div>
							<div className='flex flex-col items-center justify-center w-full lg:w-[45%]'>
								<div className='flex mb-7 self-start'>
									<div className='h-9 w-9 mr-5 bg-brand-blue-primary-400 rounded-full flex items-center justify-center'>
										<h3 className='text-brand-blue-primary text-lg font-bold mb-0'>
											2
										</h3>
									</div>
									<section>
										<h3 className='text-brand-black-primary text-lg font-bold'>
											Upload Datasets
										</h3>
									</section>
								</div>
								<DragUploadCSV
									model_url={details.model_loc}
									details={details}
									is_batch={true}
								/>
							</div>
						</section>
					</div>
				)
			)}
		</Layout>
	);
};

export default BatchPredictionForm;
