import { FC, useState } from 'react';
import { IForgotPassword } from '../../interfaces/auth.interface';
import { ISendModal } from '../../interfaces/ui.interface';
import { Form, Input } from 'antd';
import LongButton from '../helper/LongButton';
import { IUserInfo } from '../../contexts/types.interface';
import { tokenState, userDetails } from '../../api/helper';
import { PostAPIPlain } from '../../hooks/helperHook';
import Notification from '../Notification';
import MiniLoader from '../MiniLoader';

interface IProps extends ISendModal {
  prop: any;
}

const SendReport: FC<IProps> = ({ prop, setModal }) => {
  const userInfo: IUserInfo = JSON.parse(userDetails()!);
  const [isErrorP, setErrorP] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = (values: IForgotPassword) => {
    if (window.navigator.onLine) {
      setIsLoading(true);
      PostAPIPlain(
        'inference/send/email',
        {
          // model_url: prop[3],
          // data: prop[0].data.predictions,
          // model_id: prop[2],
          reference_id: prop[0].data.ref_information.reference_id,
        },
        tokenState
      )
        .then((resp) => {
          if (resp.data.status === 'success') {
            setIsLoading(false);
            Notification({
              type: 'success',
              message: 'Success!',
              description: resp.data.message,
            });
            setModal(false);
          } else {
            setIsLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description: resp.data.message,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description: e.response.data.message,
          });
        });
    } else {
      setIsLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };
  return (
    <Form
      layout='vertical'
      name='basic'
      initialValues={{
        email: userInfo && userInfo.email,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <h2 className='capitalize text-2xl text-brand-black-secondary font-bold mb-7'>
        mail report
      </h2>
      <Form.Item
        label='Email'
        name='email'
        className='mb-6'
        // rules={[
        // 	{
        // 		required: true,
        // 		message: 'Please input your email!',
        // 		type: 'email',
        // 		pattern:
        // 			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        // 	},
        // ]}
      >
        <Input
          className='w-full border h-10 px-3 rounded-full'
          placeholder='enter your email'
          disabled
        />
      </Form.Item>
      <Form.Item>
        <LongButton
          text={isLoading ? <MiniLoader /> : 'Email report'}
          disabled={isLoading}
        />
      </Form.Item>
    </Form>
  );
};

export default SendReport;
