import Layout from '../../components/Layout';
import { Link, Redirect, useLocation } from 'react-router-dom';
import MailIcon from '../../assets/svg/MailIcon';
import DownloadIcon from '../../assets/svg/DownloadIcon';
import { Table } from 'antd';
import TableWrapper from '../../components/helper/TableWrapper';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import Modal from '../../components/Modal';
import SendReport from '../../components/predictions/SendReport';
import BackIcon from '../../assets/svg/BackIcon';
import PredFeaturesList from '../../components/predictions/PredFeaturesList';

const PredictionsResult = () => {
  const [modal, setModal] = useState(false);
  const [featuresModal, setFeaturesModal] = useState(false);
  const location = useLocation<any>();
  const handleModal = () => {
    setModal(true);
  };

  const columns = [
    {
      title: 'Column Name',
      dataIndex: 'column_name',
      key: 'column_name',
      render: (text: string) => <span className='capitalize'>{text}</span>,
    },
    {
      title: '',
      dataIndex: 'decs',
      key: 'decs',
      render: (text: string) => (
        <div className='flex justify-end items-end'>
          {text.includes('Model') ? (
            <Link
              to={`/models/viewmodel/${location.state && location.state[2]}`}
            >
              <span className='underline font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-8 lg:mr-16'>
                {text}
              </span>
            </Link>
          ) : text === '78% chance of defaulting' ? (
            <div className='font-semibold text-brand-danger-900 bg-brand-danger-400 px-4 rounded-lg py-2 transition ease-out duration-500 inline-block mr-8 lg:mr-16'>
              {text}
            </div>
          ) : text === 'features' ? (
            <button
              onClick={() => setFeaturesModal(true)}
              className='flex items-center mr-12 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'
            >
              <span className='text-sm font-bold text-brand-blue-primary capitalize'>
                View Features
              </span>
            </button>
          ) : (
            <span className='font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-8 lg:mr-16'>
              {text}
            </span>
          )}
        </div>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      column_name: 'Name of model',
      decs: `Model: ${location.state && location.state[1]}`,
    },
    {
      key: '2',
      column_name: 'Predicted On',
      decs: '23/05/2021',
    },
    {
      key: '5',
      column_name: 'Prediction',
      decs: String(
        location.state &&
          location.state[0].data.predictions[0].predictions * 100
      ).substring(0, 5),
    },
    {
      key: '6',
      column_name: 'Features',
      decs: 'features',
    },
  ];

  if ((location.state && location.state.length < 1) || !location.state) {
    return <Redirect to='/home' />;
  }

  return (
    <>
      <Layout title='prediction result'>
        <Link
          to={`/predictions/single/${location.state && location.state[2]}`}
          className='text-brand-black-primary hover:text-brand-black-primary font-bold inline-flex items-center mb-10'
        >
          <BackIcon className='mr-2' /> <span>Back</span>
        </Link>
        <section className='flex flex-col lg:flex-row justify-between mb-8'>
          <div className='mb-7 text-sm text-brand-black-secondary capitalize font-bold'>
            <Link
              to={`/predictions/single/${location.state && location.state[2]}`}
              className='text-brand-blue-primary hover:text-brand-blue-primary-900 underline hover:underline'
            >
              single request
            </Link>
            <span> / {location.state && location.state[1]}</span>
          </div>
          <div className='flex'>
            <a
              href={location.state[0].data.ref_information.inference_csv}
              className='flex items-center w-[184px] mr-7 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'
            >
              <DownloadIcon className='mr-3' />
              <span className='text-sm font-bold text-brand-blue-primary capitalize'>
                download CSV
              </span>
            </a>
            <button className='flex items-center w-[184px] mr-7 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'>
              <DownloadIcon className='mr-3' />
              <span className='text-sm font-bold text-brand-blue-primary capitalize'>
                download Chart
              </span>
            </button>
            <button
              className='flex items-center w-[150px] h-10 rounded-md px-3 py-2 bg-brand-blue-primary hover:bg-brand-blue-primary-900 transition-all ease-out duration-500'
              onClick={handleModal}
            >
              <MailIcon className='mr-3' />
              <span className='text-sm font-bold text-white capitalize'>
                email report
              </span>
            </button>
          </div>
        </section>
        <TableWrapper>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            className=''
          />
        </TableWrapper>
        <div className='w-full mt-8 lg:mt-16 flex justify-center'>
          {/* <img
            src={`data:image/jpeg;base64,${location.state[0].data.explanations.local_expl_charts_byte}`}
          /> */}
          <Link
            to={{
              pathname: '/graph/result',
              state: [
                location.state[3],
                location.state[0].data.predictions[0],
                1,
                String(
                  location.state &&
                    location.state[0].data.predictions[0].predictions * 100
                ).substring(0, 5),
              ],
            }}
            className='text-lg text-brand-white hover:text-brand-white hover:underline bg-brand-black-secondary px-4 py-2 rounded-sm'
          >
            {' '}
            View Prediction Explanation
          </Link>
        </div>
      </Layout>
      {/* email modal */}
      <Modal isOpen={modal} setModal={setModal}>
        <SendReport prop={location.state} setModal={setModal} />
      </Modal>
      {/* features modal */}
      <Modal isOpen={featuresModal} setModal={setFeaturesModal}>
        <PredFeaturesList
          featureName={location.state && location.state[0].data.feature_names}
          featureValues={
            location.state && location.state[0].data.predictions[0]
          }
        />
      </Modal>
    </>
  );
};

export default PredictionsResult;
