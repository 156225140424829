import { useHistory, useLocation, Redirect } from 'react-router-dom';
import BackIcon from '../../assets/svg/BackIcon';
import ErrorMsg from '../../components/ErrorMsg';
import Layout from '../../components/Layout';
import MainLoader from '../../components/MainLoader';
import { useGraphPlot } from '../../hooks/useGraphPlot';

const GraphPlot = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { graph, isErrorP, isLoading } = useGraphPlot(
    location.state[0],
    location.state[1]
  );

  if ((location.state && location.state.length < 1) || !location.state) {
    return <Redirect to='/home' />;
  }

  return (
    <Layout title='plot'>
      <button
        onClick={history.goBack}
        className='text-brand-black-primary hover:text-brand-black-primary font-bold inline-flex items-center mb-10'
      >
        <BackIcon className='mr-2' /> <span>Back</span>
      </button>
      {isErrorP ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        graph && (
          <>
            <section className='flex justify-center mr-10'>
              <div>
                <p className='font-medium text-lg mb-2'>
                  Serial No:{' '}
                  <span className='text-brand-blue-primary font-bold'>
                    {location.state[2]}
                  </span>
                </p>
                <p className='font-medium text-lg'>
                  Prediction:{' '}
                  <span className='text-brand-blue-primary font-bold'>
                    {location.state[3]}
                  </span>
                </p>
              </div>
            </section>
            <div className='w-full lg:mt-16 flex justify-center'>
              <img src={`data:image/jpeg;base64,${graph}`} />
            </div>
          </>
        )
      )}
    </Layout>
  );
};

export default GraphPlot;
