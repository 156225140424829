import { IModelForm, ISelectModelForm } from '../../interfaces/auth.interface';
import { Form, Select } from 'antd';
import ShortButton from './ShortButton';
import { FC, useContext } from 'react';
import { ModelContext } from '../../contexts/model.context';
import ErrorMsg from '../ErrorMsg';
import MainLoader from '../MainLoader';
import { useHistory } from 'react-router-dom';
import { deletePredDetails } from '../../api/helper';

const { Option } = Select;

interface IForm {
  setModel: (state: string) => void;
  setIsModelSelected: (selected: boolean) => void;
  isBatch?: boolean;
}

const SelectModelForm: FC<IForm> = ({
  setModel,
  setIsModelSelected,
  isBatch,
}) => {
  const history = useHistory();
  const { model, isLoading, isError } = useContext(ModelContext);

  const onFinish = (values: ISelectModelForm) => {
    if (isBatch) {
      history.push(`/predictions/single/${values.model}`);
      setIsModelSelected(true);
      deletePredDetails(); //on selecting model, clear the local
    } else {
      history.push(`/predictions/batchform/${values.model}`);
      setIsModelSelected(true);
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  const data = model?.map((model) => ({
    key: model.public_id,
    value: model.model_desc,
  }));

  return (
    <>
      {isError ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        <div className='w-full lg:w-[419px] 2xl:w-5/12'>
          <p className='text-sm font-bold text-brand-black-primary mb-7'>
            Please select a model for prediction
          </p>
          <Form
            layout='vertical'
            name='basic'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Model'
              name='model'
              className='mb-6'
              rules={[
                {
                  required: true,
                  message: 'Please select model!',
                  type: 'string',
                },
              ]}
            >
              <Select
                placeholder='select model'
                className='w-full'
                size='large'
                showArrow={true}
              >
                {data?.map((data) => (
                  <Option key={data.key} value={data.key}>
                    {data.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <ShortButton text='Continue' />
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default SelectModelForm;
