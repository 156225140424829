import { createContext, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tokenState, userDetails } from '../api/helper';
import { IAuth, IUserInfo } from './types.interface';

const defaultUser: IAuth = {
  isAuthenticated: () => {},
  token: tokenState(),
  setAuth: () => {},
  logOut: () => {},
  userInfo: null,
  setUserInfo: () => {},
};

export const AuthContext = createContext<IAuth>(defaultUser);

const AuthProvider: FC = ({ children }) => {
  const history = useHistory();

  const [token, setTokenState] = useState<string | null>(defaultUser.token);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(
    defaultUser.userInfo
  );

  const setAuth = (toks: string) => {
    setTokenState(toks);
    setUserInfo(userDetails && JSON.parse(userDetails()!));
  };

  const isAuthenticated = () => {
    return token ? true : false;
  };

  const logOut = () => {
    setTokenState(null);
    localStorage.clear();
    history.push('/');
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, token, setAuth, logOut, userInfo, setUserInfo }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
