import { useEffect, useState } from 'react';
import { PostAPI } from './helperHook';

interface ILicenseReq {
	u_id?: string;
	c_id?: string;
}

interface IResponse {
	firstname: string;
	lastname: string;
	email: string;
}

interface ILicenseResp {
	data: IResponse;
	status: string;
}

export const useGetLicenseInfo = (license: ILicenseReq) => {
	const [isErrorP, setErrorP] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [user, setUser] = useState<IResponse | null>();

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine) {
			PostAPI<ILicenseResp, ILicenseReq>(
				'onboardinglicense/licenseinfo',
				license
			)
				.then((resp) => {
					if (resp.data.status === 'success') {
						setIsLoading(false);
						setErrorP(false);
						setUser(resp.data.data);
					}
				})
				.catch((e) => {
					setIsLoading(false);
					setErrorP(true);
					setUser(null);
				});
		} else {
			setIsLoading(false);
			setErrorP(true);
		}

		return () => {};
	}, []);

	return { isErrorP, isLoading, user } as const;
};
