import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IPredict {
  //   setIsModelSelected: (selected: boolean) => void;
  modelId?: string;
}

export const PredictionResultTab: FC<IPredict> = ({ modelId }) => {
  const location = useLocation();
  return (
    <div className='flex border-b-2 mb-10'>
      <Link
        to={`/predictions/single/${modelId}`}
        className={`text-base capitalize mr-8 pb-4 ${
          location.pathname.includes('/predictions/single/')
            ? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
            : 'text-brand-black-secondary hover:text-brand-black-primary'
        } `}
      >
        single request
      </Link>
      <Link
        to={`/predictions/batchform/${modelId}`}
        className={`text-base capitalize mr-8 pb-4 ${
          location.pathname.includes('/predictions/batch')
            ? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
            : 'text-brand-black-secondary hover:text-brand-black-primary'
        } `}
      >
        batch request
      </Link>
    </div>
  );
};
