import { FC, MouseEvent, useContext, useState } from 'react';
import Sidebar from './Sidebar';
import MenuIcon from '../assets/svg/MenuIcon';
import DropDownIcon from '../assets/svg/DropDownIcon';
import DropUpIcon from '../assets/svg/DropUpIcon';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/auth.context';
import { userDetails } from '../api/helper';
import { IUserInfo } from '../contexts/types.interface';
import IdleTimeOut from './IdleTimeOut';

interface ILayout {
  title: string;
}

const Layout: FC<ILayout> = ({ title, children }) => {
  const { logOut } = useContext(AuthContext);
  const userInfo: IUserInfo = JSON.parse(userDetails()!);
  const [state, setState] = useState({
    isModalOpen: false,
    isMenuDrop: false,
  });

  const handleModal = () =>
    setState({ ...state, isModalOpen: !state.isModalOpen });

  const handleMenu = () =>
    setState({ ...state, isMenuDrop: !state.isMenuDrop });

  const handleOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.classList.contains('nav-wrap')) {
      setState({ ...state, isMenuDrop: false });
    }
  };

  return (
    <div
      className='min-h-screen bg-layout-bg lg:flex lg:justify-between nav-wrap'
      onClick={handleOutsideClick}
    >
      <Sidebar isModalOpen={state.isModalOpen} handleModal={handleModal} />
      <section className='w-full h-auto'>
        <header className='px-4 py-2 flex justify-between items-center lg:px-8 lg:py-8 w-full lg:justify-between'>
          <h1 className='hidden lg:block pl-5 text-[32px] capitalize font-bold mb-0'>
            {title}
          </h1>
          <div className='lg:hidden'>
            <MenuIcon className='cursor-pointer' onClick={handleModal} />
          </div>
          <nav className='flex items-center'>
            <div
              className='flex h-10 cursor-pointer justify-center items-center'
              onClick={handleMenu}
            >
              <div className='h-9 w-9 mr-2 bg-brand-black-secondary rounded-full flex items-center justify-center'>
                <h3 className='text-brand-white text-lg font-bold mb-0'>
                  {userInfo?.firstname.charAt(0)}
                </h3>
              </div>
              {state.isMenuDrop ? <DropUpIcon /> : <DropDownIcon />}
            </div>
            <div
              className={`bg-brand-white rounded-lg h-auto absolute z-10 w-60 border right-6 shadow-sm top-20 transition duration-500 ease-in-out ${
                state.isMenuDrop ? 'block' : 'hidden'
              }`}
            >
              <section className='bg-brand-blue-primary-200 pl-6 py-4 w-full flex items-center mb-4'>
                <div className='h-9 w-9 mr-2 bg-brand-black-secondary rounded-full flex items-center justify-center'>
                  <h3 className='text-brand-white text-lg font-bold mb-0'>
                    {userInfo?.firstname.charAt(0)}
                  </h3>
                </div>
                <div className=''>
                  <h5 className='font-semibold text-base mb-0'>
                    {`${userInfo?.firstname} ${userInfo?.lastname}`}
                  </h5>
                  <p className='text-brand-black-secondary capitalize mb-0'>
                    {userInfo?.admin ? 'admin' : 'member'}
                  </p>
                </div>
              </section>
              <Link
                to='/settings/profile'
                className='block pl-6 my-4 text-brand-black-secondary hover:text-brand-black-primary capitalize font-semibold'
              >
                settings
              </Link>
              <div className='border-t'>
                <button
                  className='block pl-6 my-4 text-brand-black-secondary hover:text-brand-black-primary capitalize font-semibold'
                  onClick={logOut}
                >
                  log out
                </button>
              </div>
            </div>
          </nav>
        </header>
        <section className='px-4 py-2 lg:pl-16 lg:pr-10 w-full'>
          {children}
        </section>
      </section>
      <IdleTimeOut />
    </div>
  );
};

export default Layout;
