import { Table } from 'antd';
import { FC } from 'react';
import ArrowDownIcon from '../assets/svg/ArrowDownIcon';
import ArrowUpIcon from '../assets/svg/ArrowUpIcon';
import CategoryIcon from '../assets/svg/CategoryIcon';
import TableWrapper from './helper/TableWrapper';

interface IState {
  categorical: {
    state: boolean;
    value: string;
  };
  numerical: {
    state: boolean;
    value: string;
  };
  date: {
    state: boolean;
    value: string;
  };
  freeText: {
    state: boolean;
    value: string;
  };
}

interface IFeatures {
  title: string;
  column: string[];
  features: any;
  state: IState;
  featureImportance: any;
  setState: (value: IState) => void;
}

const FeaturesList: FC<IFeatures> = ({
  title,
  column,
  features,
  state,
  featureImportance,
  setState,
}) => {
  const columns = [
    {
      title: 'Column Name',
      dataIndex: 'column_name',
      key: 'column_name',
    },
    {
      title: 'Feature Importance',
      dataIndex: 'feature_importance',
      key: 'feature_importance',
    },
    {
      title: 'Description',
      dataIndex: 'data_type',
      key: 'data_type',
    },
  ];

  const data = column.map((name: string, i) => ({
    key: i,
    column_name: name,
    feature_importance: featureImportance[name],
    data_type: features[name],
  }));

  //buttons to toggle feature tables
  const handleToggleCat = () => {
    setState({
      categorical: {
        state: !state.categorical.state,
        value: 'categorical features',
      },
      numerical: { state: state.numerical.state, value: 'numerical features' },
      date: { state: state.date.state, value: 'date features' },
      freeText: { state: state.freeText.state, value: 'freeText features' },
    });
  };

  const handleToggleNum = () => {
    setState({
      categorical: {
        state: state.categorical.state,
        value: 'categorical features',
      },
      numerical: { state: !state.numerical.state, value: 'numerical features' },
      date: { state: state.date.state, value: 'date features' },
      freeText: { state: state.freeText.state, value: 'freeText features' },
    });
  };

  const handleToggleDate = () => {
    setState({
      categorical: {
        state: state.categorical.state,
        value: 'categorical features',
      },
      numerical: { state: state.numerical.state, value: 'numerical features' },
      date: { state: !state.date.state, value: 'date features' },
      freeText: { state: state.freeText.state, value: 'freeText features' },
    });
  };

  const handleToggleFreeTxt = () => {
    setState({
      categorical: {
        state: state.categorical.state,
        value: 'categorical features',
      },
      numerical: { state: state.numerical.state, value: 'numerical features' },
      date: { state: state.date.state, value: 'date features' },
      freeText: { state: !state.freeText.state, value: 'freeText features' },
    });
  };

  //logic to render and toggle table dynamically
  const renderSwitch = (param: string) => {
    switch (param) {
      case state.categorical.value:
        return (
          <section className='card-shadow p-4 bg-brand-white mb-7'>
            <div className='flex justify-around'>
              <div className='flex items-center w-full border-b border-brand-black-disabled pb-3 mb-3'>
                <CategoryIcon className='mr-3' />
                <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                  {title}
                </p>
              </div>
              <div
                role='button'
                onClick={() => handleToggleCat()}
                className='flex items-center cursor-pointer justify-end w-full border-b border-brand-black-disabled pb-3 mb-3'
              >
                <p className='text-base mb-0 font-bold text-brand-blue-primary capitalize mr-2'>
                  {state.categorical.state ? 'close' : 'view'}
                </p>
                {!state.categorical.state ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {state.categorical.state && (
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className=''
                />
              </TableWrapper>
            )}
          </section>
        );
      case state.numerical.value:
        return (
          <section className='card-shadow p-4 bg-brand-white mb-7'>
            <div className='flex justify-around'>
              <div className='flex items-center w-full border-b border-brand-black-disabled pb-3 mb-3'>
                <CategoryIcon className='mr-3' />
                <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                  {title}
                </p>
              </div>
              <div
                role='button'
                onClick={() => handleToggleNum()}
                className='flex items-center cursor-pointer justify-end w-full border-b border-brand-black-disabled pb-3 mb-3'
              >
                <p className='text-base mb-0 font-bold text-brand-blue-primary capitalize mr-2'>
                  {state.numerical.state ? 'close' : 'view'}
                </p>
                {!state.numerical.state ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {state.numerical.state && (
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className=''
                />
              </TableWrapper>
            )}
          </section>
        );
      case state.date.value:
        return (
          <section className='card-shadow p-4 bg-brand-white mb-7'>
            <div className='flex justify-around'>
              <div className='flex items-center w-full border-b border-brand-black-disabled pb-3 mb-3'>
                <CategoryIcon className='mr-3' />
                <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                  {title}
                </p>
              </div>
              <div
                role='button'
                onClick={() => handleToggleDate()}
                className='flex items-center cursor-pointer justify-end w-full border-b border-brand-black-disabled pb-3 mb-3'
              >
                <p className='text-base mb-0 font-bold text-brand-blue-primary capitalize mr-2'>
                  {state.date.state ? 'close' : 'view'}
                </p>
                {!state.date.state ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {state.date.state && (
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className=''
                />
              </TableWrapper>
            )}
          </section>
        );
      case state.freeText.value:
        return (
          <section className='card-shadow p-4 bg-brand-white mb-7'>
            <div className='flex justify-around'>
              <div className='flex items-center w-full border-b border-brand-black-disabled pb-3 mb-3'>
                <CategoryIcon className='mr-3' />
                <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                  {title}
                </p>
              </div>
              <div
                role='button'
                onClick={() => handleToggleFreeTxt()}
                className='flex items-center cursor-pointer justify-end w-full border-b border-brand-black-disabled pb-3 mb-3'
              >
                <p className='text-base mb-0 font-bold text-brand-blue-primary capitalize mr-2'>
                  {state.freeText.state ? 'close' : 'view'}
                </p>
                {!state.freeText.state ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {state.freeText.state && (
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className=''
                />
              </TableWrapper>
            )}
          </section>
        );
    }
  };

  return <>{renderSwitch(title)}</>;
};

export default FeaturesList;
