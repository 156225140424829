import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IPredict {
	setIsModelSelected: (selected: boolean) => void;
}

export const PredictionTab: FC<IPredict> = ({ setIsModelSelected }) => {
	const location = useLocation();
	return (
		<div className='flex border-b-2 mb-10'>
			<Link
				to='/predictions'
				className={`text-base capitalize mr-8 pb-4 ${
					location.pathname === '/predictions'
						? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
						: 'text-brand-black-secondary hover:text-brand-black-primary'
				} `}
				onClick={() => setIsModelSelected(false)}
			>
				single request
			</Link>
			<Link
				to='/predictions/batch'
				className={`text-base capitalize mr-8 pb-4 ${
					location.pathname === '/predictions/batch'
						? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
						: 'text-brand-black-secondary hover:text-brand-black-primary'
				} `}
				onClick={() => setIsModelSelected(false)}
			>
				batch request
			</Link>
		</div>
	);
};
