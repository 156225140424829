import Header from '../components/onboarding/Header';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import { ILogin } from '../interfaces/auth.interface';
import { Link, Redirect } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/auth.context';
import { Auth } from '../api/api';
import MiniLoader from '../components/MiniLoader';
import Notification from '../components/Notification';
import creditNetLogo from '../assets/images/creditnet_logo.png';

const FormInputTarget = styled.div`
  input {
    background: none;
  }
`;

const Variant = {
  initial1: {
    y: 300,
    opacity: 0,
  },
  animate1: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  initial2: {
    x: 300,
    opacity: 0,
  },
  animate2: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: 'easeOut',
    },
  },
};

const Login = () => {
  const [error, setError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const context = useContext(AuthContext);
  const onFinish = (values: ILogin) => {
    let creds = {
      email: values.email,
      password: values.password,
    };
    setIsLoggingIn(true);
    setError(false);
    Auth.login(creds)
      .then((resp) => {
        if (resp.status === 'success') {
          setIsLoggingIn(false);
          setError(false);
          Notification({
            type: 'success',
            message: 'Success!',
            description: 'Login Successful!',
          });
          localStorage.setItem('token', resp.Authorization);
          localStorage.setItem(
            'userInfo',
            JSON.stringify(resp.data.user_details)
          );
          context.setAuth(resp.Authorization);
          context.setUserInfo(resp.data.user_details);
        }
      })
      .catch((err) => {
        setIsLoggingIn(false);
        setError(true);
        Notification({
          type: 'error',
          message: 'Error!',
          description: 'Invalid email or password',
        });
      });
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  if (context.isAuthenticated()) {
    return <Redirect to='/home' />;
  }

  return (
    <div className='p-8 lg:p-16 mb-8 min-h-screen bg-brand-white'>
      <Header />
      <section className='flex flex-col lg:flex-row h-full'>
        <div className='flex flex-col justify-between w-full mb-6 lg:mb-0'>
          <motion.div
            className='w-full lg:w-9/12'
            variants={Variant}
            initial='initial1'
            animate='animate1'
          >
            <Link to='/'>
              <img
                src={creditNetLogo}
                alt='creditNet Logo'
                className='w-[227px] mb-3'
              />
            </Link>
            <p className='text-base font-regular text-brand-black-secondary'>
              Predicting the future behavior of borrowers.
            </p>
          </motion.div>
          <div className='sm:hidden lg:block'>
            <p className='text-sm text-brand-black-secondary font-semibold'>
              Powered by{' '}
              <a
                href='https://insidaticsai.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='text-brand-blue-primary hover:text-brand-blue-primary-900'
              >
                InsidaticsAI
              </a>
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-evenly items-center w-full h-full'>
          <motion.div
            className='w-full lg:w-10/12 h-full'
            variants={Variant}
            initial='initial2'
            animate='animate2'
          >
            <Form
              layout='vertical'
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label='Email'
                name='email'
                className='mb-4'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email',
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                ]}
              >
                <Input
                  className='w-full bg-transparent border h-10 px-3 rounded-md'
                  placeholder='enter your email'
                />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                className='mb-4'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <FormInputTarget>
                  <Input.Password
                    className='w-full bg-transparent border h-10 px-3 rounded-md'
                    placeholder='enter your password'
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </FormInputTarget>
              </Form.Item>
              <div className='flex justify-end'>
                <Link
                  to='forgot-password'
                  className='text-sm font-semibold text-brand-blue-primary hover:text-brand-blue-primary-900'
                >
                  Forgot password?
                </Link>
              </div>

              <Form.Item>
                <button
                  className='w-full mt-10 h-10 capitalize bg-brand-blue-primary text-brand-white font-bold hover:bg-brand-blue-primary-900 rounded-md'
                  type='submit'
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? <MiniLoader /> : 'log in'}
                </button>
              </Form.Item>
            </Form>
            {/* <p className='text-sm text-brand-black-secondary font-semibold text-center'>
							Don’t have an account?{' '}
							<Link
								to='signup'
								className='text-brand-blue-primary hover:text-brand-blue-primary-900'
							>
								Sign Up
							</Link>
						</p> */}
          </motion.div>
          <div className='fixed bottom-6 sm:block lg:hidden'>
            <p className='text-sm text-brand-black-secondary font-semibold text-center self-stretch'>
              Powered by{' '}
              <a
                href='https://insidaticsai.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='text-brand-blue-primary hover:text-brand-blue-primary-900'
              >
                InsidaticsAI
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
