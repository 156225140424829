import { Table } from 'antd';
import { FC } from 'react';
import TableWrapper from '../../components/helper/TableWrapper';

interface IPredict {
	features?: string[] | null;
	fieldDetails: any;
}

const PredictedColumns: FC<IPredict> = ({ features, fieldDetails }) => {
	const columns = [
		{
			title: 'Column Name',
			dataIndex: 'column_name',
			key: 'column_name',
		},
		{
			title: 'Data Type',
			dataIndex: 'data_type',
			key: 'data_type',
			render: (text: string) => (
				<span className='capitalize'>{text}</span>
			),
		},
	];

	const data = features?.map((field, i) => ({
		key: i,
		column_name: field,
		data_type: fieldDetails[field].type,
	}));
	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
				className=''
			/>
		</TableWrapper>
	);
};

export default PredictedColumns;
