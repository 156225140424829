import Layout from '../../components/Layout';
import { Table } from 'antd';
import TableWrapper from '../../components/helper/TableWrapper';
import FillButton from '../../components/helper/FillButton';
import AddIcon from '../../assets/svg/AddIcon';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import Modal from '../../components/Modal';
import { useState } from 'react';
import CreateUser from '../../components/CreateUser';
import { Link } from 'react-router-dom';
import { useGetAllUsers } from '../../hooks/useGetAllUsers';
import ErrorMsg from '../../components/ErrorMsg';
import MainLoader from '../../components/MainLoader';
import EditIcon from '../../assets/svg/EditIcon';

const Users = () => {
  const [modal, setModal] = useState(false);
  const { isError, isLoading, users, setUsers } = useGetAllUsers();
  const columns = [
    {
      title: 'Sn',
      dataIndex: 'sn',
      key: 'sn',
      render: (id: number) => (
        <>
          <span>{id}</span>
        </>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => (
        <>
          {value === 'False' ? (
            <span className='font-normal capitalize px-4 py-2 lg:px-6 text-brand-danger-700 bg-brand-danger-400 rounded-full'>
              Inactive
            </span>
          ) : (
            <span className='font-normal capitalize px-4 py-2 lg:px-6 text-brand-success-900 bg-brand-success-400 rounded-full'>
              Active
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (id: number) => (
        <div className='inline-flex justify-center items-center'>
          <Link to={`users/edituser/${id}`}>
            <span className='font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-4'>
              <EditIcon />
            </span>
          </Link>
          <button className=''>
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

  const data = users?.map((user, i) => ({
    key: user.public_id,
    sn: i + 1,
    firstname: user.firstname,
    lastname: user.lastname,
    created_on: user.registered_on.substr(0, user.registered_on.indexOf(' ')),
    status: user.activate_status,
    action: user.public_id,
  }));

  return (
    <>
      <Layout title='users'>
        {isError ? (
          <ErrorMsg />
        ) : isLoading ? (
          <MainLoader />
        ) : (
          <>
            <section className='flex mb-3 justify-end'>
              <FillButton text='add user' handleClick={() => setModal(true)}>
                <AddIcon />
              </FillButton>
            </section>
            <section className='card-shadow bg-brand-white w-full p-8 mb-8'>
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{ position: ['bottomCenter'] }}
                  className=''
                />
              </TableWrapper>
            </section>
          </>
        )}
      </Layout>
      <Modal isOpen={modal} setModal={setModal}>
        <CreateUser setModal={setModal} setUsers={setUsers} users={users} />
      </Modal>
    </>
  );
};

export default Users;
