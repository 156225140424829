import axios, { AxiosResponse } from 'axios';
import {
  ICreateUser,
  IForgotPassword,
  ILogin,
} from '../interfaces/auth.interface';
import {
  IGetModels,
  IResponseDashboard,
  IResponseLogin,
  IResponseUser,
} from '../interfaces/responses.interface';
import { tokenState } from './helper';


export const URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: URL,
  timeout: 15000,
  headers: {
    Authorization: `Bearer ${tokenState() && tokenState()}`,
  },
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

export const Auth = {
  login: (cred: ILogin): Promise<IResponseLogin> =>
    requests.post('auth/login', cred),
  forgotPassword: (cred: IForgotPassword) =>
    requests.post('auth/forgotpassword', cred),
  resetPassword: (cred: any) => requests.post('auth/setpassword', cred),
};

export const Model = {
  getAllModels: (url: string): Promise<IGetModels> => instance.get(url),
};

export const Dashboard = {
  getDashboard: () => requests.get('dashboard/'),
};

export const User = {
  createUser: (cred: ICreateUser): Promise<IResponseUser> =>
    requests.post('user/', cred),
};
