import Layout from '../../components/Layout';
import { Table } from 'antd';
import TableWrapper from '../../components/helper/TableWrapper';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ModelContext } from '../../contexts/model.context';
import ErrorMsg from '../../components/ErrorMsg';
import MainLoader from '../../components/MainLoader';

const Models = () => {
  const { model, isLoading, isError } = useContext(ModelContext);

  const columns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      render: (id: number) => (
        <>
          <span>{id}</span>
        </>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Model Type',
      dataIndex: 'model_type',
      key: 'model_type',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (id: number) => (
        <>
          <Link to={`models/viewmodel/${id}`}>
            <span className='font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-8 lg:mr-16'>
              View
            </span>
          </Link>
        </>
      ),
    },
  ];

  const data = model?.map((data, i) => ({
    key: data.public_id,
    sn: i + 1,
    title: data.model_desc,
    created_on: data.created_on.substring(0, 16),
    model_type: data.model_type ? data.model_type : 'N/A',
    action: data.public_id,
  }));
  return (
    <Layout title='models'>
      {isError ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        <>
          <section className='card-shadow bg-brand-white w-full p-8 mb-8'>
            <TableWrapper>
              <Table
                columns={columns}
                dataSource={data}
                pagination={{ position: ['bottomCenter'] }}
                className=''
              />
            </TableWrapper>
          </section>
        </>
      )}
    </Layout>
  );
};

export default Models;
