import axios from 'axios';
import { URL } from '../api/api';
import { IResponseUser } from '../interfaces/responses.interface';

export const GetAPI = <T>(url: string, token: () => string | null) => {
	return axios.get<T>(`${URL}${url}`, {
		timeout: 15000,
		headers: {
			Authorization: `Bearer ${token() && token()}`,
		},
	});
};

export const GetAPIPlain = <T>(url: string) => {
	return axios.get<T>(`${URL}${url}`, {
		timeout: 15000,
	});
};

export const PostAPI = <T, D>(url: string, cred: D) => {
	return axios.post<T>(`${URL}${url}`, cred, {
		timeout: 15000,
	});
};

export const PostAPIPlain = <T>(
	url: string,
	cred: T,
	token: () => string | null
) => {
	return axios.post(`${URL}${url}`, cred, {
		// timeout: 15000,
		headers: {
			Authorization: `Bearer ${token() && token()}`,
		},
	});
};

export const CreateUserAPI = <T>(
	cred: T,
	token: () => string | null
): Promise<IResponseUser> => {
	return axios
		.post(`${URL}user/`, cred, {
			timeout: 15000,
			headers: {
				Authorization: `Bearer ${token() && token()}`,
			},
		})
		.then((resp) => resp.data);
};
