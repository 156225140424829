import axios from 'axios';
import { useEffect, useState } from 'react';
import { URL } from '../api/api';
import { tokenState } from '../api/helper';

interface IResponse {
    firstname: string;
    lastname: string;
	email: string
}

interface IPassword {
    data: IResponse
    status: string
}

export const useGraphPlot = (model_url: string, inference_kwarg: any) => {
	const [isErrorP, setErrorP] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [graph, setGraph] = useState<any | null>();
   

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine) {
			axios.post(`${URL}chart/local/json`, {model_url, data: inference_kwarg}, {headers: {
                Authorization: `Bearer ${tokenState() && tokenState()}`,
            }}).then((resp) => {
				if (resp.data.status === 'success') {
					setIsLoading(false);
					setErrorP(false);
					setGraph(resp.data.data);
				}
			}).catch(e => {
                setIsLoading(false);
				setErrorP(true);
				setGraph(null);
            });
		} else {
            setIsLoading(false);
			setErrorP(true);
        }

		return () => {};
	}, []);

	return { graph, isErrorP, isLoading } as const;
};
