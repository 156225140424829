import Layout from '../../components/Layout';
import { Table } from 'antd';
import TableWrapper from '../../components/helper/TableWrapper';
import { Link, Redirect, useLocation } from 'react-router-dom';
import BackIcon from '../../assets/svg/BackIcon';
import DownloadIcon from '../../assets/svg/DownloadIcon';
import MailIcon from '../../assets/svg/MailIcon';
import { useState } from 'react';
import SendReport from '../../components/predictions/SendReport';
import Modal from '../../components/Modal';
import PredFeaturesList from '../../components/predictions/PredFeaturesList';
import { PostAPIPlain } from '../../hooks/helperHook';
import { tokenState } from '../../api/helper';
import Notification from '../../components/Notification';
import MiniLoader from '../../components/MiniLoader';

const PredictionsBatchResult = () => {
  const [modal, setModal] = useState(false);
  const [featuresModal, setFeaturesModal] = useState(false);
  const [predIndex, setPredIndex] = useState(0);
  const location = useLocation<any>();
  const [isErrorP, setErrorP] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [graph, setGraph] = useState<any | null>();

  const handleGlobalChart = () => {
    if (window.navigator.onLine) {
      setIsLoading(true);
      PostAPIPlain(
        'chart/global/json',
        {
          model_url: location.state[3],
          data: location.state[0].data.predictions,
        },
        tokenState
      )
        .then((resp) => {
          if (resp.data.status === 'success') {
            setIsLoading(false);
            Notification({
              type: 'success',
              message: 'Success!',
              description: resp.data.message,
            });
            setGraph(resp.data.data);
          } else {
            setIsLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description: resp.data.message,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description: e.response.data.message,
          });
        });
    } else {
      setIsLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const columns = [
    {
      title: 'Sn',
      dataIndex: 'sn',
      key: 'sn',
      render: (id: number) => (
        <>
          <span>{id}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Prediction',
      dataIndex: 'prediction',
      key: 'prediction',
      render: (prediction: number) => (
        <span className='font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-8 lg:mr-16'>
          {prediction}
        </span>
      ),
    },
    {
      title: 'Features',
      key: 'feature',
      dataIndex: 'feature',
      render: (id: number) => (
        <button
          className='flex items-center mr-7 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'
          onClick={() => {
            setPredIndex(id);
            setFeaturesModal(true);
          }}
        >
          <span className='font-semibold text-brand-blue-primary-900'>
            View Features
          </span>
        </button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (data: any) => (
        <>
          <Link
            className='inline-flex items-center h-10 rounded-md px-3 py-2 bg-brand-black-secondary transition-all ease-out duration-500'
            to={{
              pathname: '/graph/result',
              state: [
                location.state[3],
                data.feature,
                data.index + 1,
                data.prediction,
              ],
            }}
          >
            <span className='font-semibold text-brand-white'>
              View Prediction Explanation
            </span>
          </Link>
        </>
      ),
    },
  ];

  const prediction: any[] =
    location.state && location.state[0].data.predictions;

  const data = prediction.map((data, i) => ({
    key: i,
    sn: i + 1,
    prediction: (data.predictions * 100).toString().substring(0, 4),
    feature: i,
    action: {
      feature: location.state[0].data.predictions[i],
      index: i,
      prediction: (data.predictions * 100).toString().substring(0, 4),
    },
  }));

  if ((location.state && location.state.length < 1) || !location.state) {
    return <Redirect to='/home' />;
  }

  return (
    <>
      <Layout title='prediction result'>
        <Link
          to={`/predictions/batchform/${location.state && location.state[2]}`}
          className='text-brand-black-primary hover:text-brand-black-primary font-bold inline-flex items-center mb-10'
        >
          <BackIcon className='mr-2' /> <span>Back</span>
        </Link>
        <section className='flex flex-col lg:flex-row justify-between'>
          <div className='mb-7 text-sm text-brand-black-secondary capitalize font-bold'>
            <Link
              to='/predictions/batch'
              className='text-brand-blue-primary hover:text-brand-blue-primary-900 underline hover:underline'
            >
              {location.state && location.state[4] ? 'batch' : 'single'} request
            </Link>
            <span> / {location.state && location.state[1]}</span>
          </div>
          <div className='flex'>
            <a
              href={location.state[0].data.ref_information.inference_csv}
              className='flex items-center w-[184px] mr-7 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'
            >
              <DownloadIcon className='mr-3' />
              <span className='text-sm font-bold text-brand-blue-primary capitalize'>
                download CSV
              </span>
            </a>
            <button className='flex items-center w-[184px] mr-7 h-10 rounded-md px-3 py-2 border border-brand-blue-primary hover:bg-brand-blue-primary-400 transition-all ease-out duration-500'>
              <DownloadIcon className='mr-3' />
              <span className='text-sm font-bold text-brand-blue-primary capitalize'>
                download Chart
              </span>
            </button>
            <button
              className='flex items-center w-[150px] h-10 rounded-md px-3 py-2 bg-brand-blue-primary hover:bg-brand-blue-primary-900 transition-all ease-out duration-500'
              onClick={() => setModal(true)}
            >
              <MailIcon className='mr-3' />
              <span className='text-sm font-bold text-white capitalize'>
                email report
              </span>
            </button>
          </div>
        </section>
        <section className='card-shadow bg-brand-white w-full p-8 mb-8'>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ position: ['bottomCenter'] }}
              className=''
            />
          </TableWrapper>
        </section>
        {/* Global explanation chart section */}
        {/* <section className=''>
          <div className='flex justify-center'>
            <button
              onClick={handleGlobalChart}
              className='inline-block w-[234px] text-lg text-brand-white hover:text-brand-white hover:underline bg-brand-blue-primary px-4 py-2 mt-2 rounded-sm'
              disabled={isLoading}
            >
              {isLoading ? <MiniLoader /> : 'View Global Explanation'}
            </button>
          </div>
          <section className={`${graph ? 'block' : 'hidden'}`}>
            <div className='w-full mt-8 lg:mt-16 flex justify-center'>
              <img
                src={`data:image/jpeg;base64,${graph && graph.global_explanation_charts_byte.feature_importance_plot}`}
              />
            </div>
            <div className='w-full mt-8 lg:mt-16 flex justify-center'>
              <img
                src={`data:image/jpeg;base64,${graph && graph.global_explanation_charts_byte.predictions_distribution}`}
              />
            </div>
          </section>
        </section> */}
      </Layout>
      {/* email modal */}
      <Modal isOpen={modal} setModal={setModal}>
        <SendReport prop={location.state} setModal={setModal} />
      </Modal>
      {/* features modal */}
      <Modal isOpen={featuresModal} setModal={setFeaturesModal}>
        <PredFeaturesList
          featureName={location.state && location.state[0].data.feature_names}
          featureValues={
            location.state && location.state[0].data.predictions[predIndex]
          }
        />
      </Modal>
    </>
  );
};

export default PredictionsBatchResult;
