import { FC, MouseEvent } from 'react';
import { IModal } from '../interfaces/ui.interface';
import { motion } from 'framer-motion';
import CloseIcon from '../assets/svg/CloseIcon';

const Variant = {
	initial1: {
		scale: 0,
		opacity: 0,
	},
	animate1: {
		scale: 1,
		opacity: 1,
		transition: {
			duration: 0.5,
			ease: 'easeOut',
		},
	},
	initial2: {
		scale: 1,
		opacity: 1,
	},
	animate2: {
		scale: 0,
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: 'easeOut',
		},
	},
};

const ModalStatic: FC<IModal> = ({ isOpen, setModal, children }) => {

	return (
		<motion.div
			className={`h-screen w-screen bg-brand-blue-primary bg-opacity-30 z-30 top-0 fixed transform scale-105 transition-all ease-in-out duration-100 ${
				isOpen ? 'block' : 'hidden'
			}`}
			variants={Variant}
			initial={isOpen ? 'initial1' : 'animate2'}
			animate={isOpen ? 'animate1' : 'animate2'}
		>
			<div className='flex flex-col justify-center items-center h-full w-full open-nav'>
				<section className='w-11/12 lg:w-[479px] 2xl:w-6/12 bg-brand-white card-shadow flex justify-center items-center mt-5'>
					<div className='w-11/12 py-8'>{children}</div>
				</section>
			</div>
		</motion.div>
	);
};

export default ModalStatic;
