import Layout from '../../components/Layout';
import { SettingTab } from '../../components/helper/SettingTab';
import { Form, Input, Select } from 'antd';
import ShortButton from '../../components/predictions/ShortButton';
import { IProfile } from '../../interfaces/auth.interface';
import { IUserInfo } from '../../contexts/types.interface';
import { userDetails } from '../../api/helper';

const { Option } = Select;

const SettingsProfile = () => {
  const userInfo: IUserInfo = JSON.parse(userDetails()!);
  const onFinish = (values: IProfile) => {
    let creds = {
      firstname: values.firstname,
      lastname: values.lastname,
      company_name: values.company_name,
      category: values.category,
      email: values.email,
    };
    console.log(creds);
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  const data = [
    { key: 1, value: 'banking' },
    { key: 2, value: 'investment' },
    { key: 3, value: 'insurance' },
    { key: 4, value: 'telecommunication' },
    { key: 5, value: 'lending' },
  ];

  return (
    <Layout title='settings'>
      <SettingTab />
      <Form
        layout='vertical'
        name='basic'
        initialValues={{
          firstname: userInfo && userInfo.firstname,
          lastname: userInfo && userInfo.lastname,
          company_name: userInfo && userInfo.company,
          email: userInfo && userInfo.email,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='w-full'
      >
        <div className='grid grid-cols-2 w-full lg:w-10/12'>
          <Form.Item
            label='First Name'
            name='firstname'
            className='mb-6 w-[90%]'
            rules={[
              {
                required: true,
                message: 'Please input your firstname!',
                type: 'string',
              },
            ]}
          >
            <Input
              className='w-full border h-10 px-3 rounded-md'
              placeholder='enter your firstname'
              disabled
            />
          </Form.Item>
          <Form.Item
            label='Last Name'
            name='lastname'
            className='mb-6 w-[90%]'
            rules={[
              {
                required: true,
                message: 'Please input your lastname!',
                type: 'string',
              },
            ]}
          >
            <Input
              className='w-full border h-10 px-3 rounded-md'
              placeholder='enter your lastname'
              disabled
            />
          </Form.Item>
          <Form.Item
            label='Company Name'
            name='company_name'
            className='mb-6 w-[90%]'
            rules={[
              {
                required: true,
                message: 'Please input your company name!',
                type: 'string',
              },
            ]}
          >
            <Input
              className='w-full border h-10 px-3 rounded-md'
              placeholder='enter your company name'
              disabled
            />
          </Form.Item>
          <Form.Item
            label='Permissions'
            name='category'
            className='mb-6 w-[90%]'
            rules={[
              {
                required: true,
                message: 'assigned permissions',
                type: 'number',
              },
            ]}
          >
            <Select
              placeholder='assigned permissions'
              className='w-full'
              size='large'
              showArrow={true}
              disabled
            >
              {data.map((data) => (
                <Option key={data.key} value={data.key}>
                  {data.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='mb-6 w-[90%]'
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              },
            ]}
          >
            <Input
              className='w-full  border h-10 px-3 rounded-md'
              placeholder='enter your email'
              disabled
            />
          </Form.Item>
        </div>
        {/* <Form.Item>
					<ShortButton text='Save Changes' />
				</Form.Item> */}
      </Form>
    </Layout>
  );
};

export default SettingsProfile;
