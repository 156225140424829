import { useEffect, useState } from 'react';
import { GetAPIPlain } from './helperHook';

interface IResponse {
    firstname: string;
    lastname: string;
	email: string
}

interface IPassword {
    data: IResponse
    status: string
}

export const usePasswordReset = (id: any) => {
	const [isErrorP, setErrorP] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [user, setUser] = useState<IResponse | null>();

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine) {
			GetAPIPlain<IPassword>(`user/encrypted/${id}`).then((resp) => {
				if (resp.data.status === 'success') {
					setIsLoading(false);
					setErrorP(false);
					setUser(resp.data.data);
				}
			}).catch(e => {
                setIsLoading(false);
				setErrorP(true);
				setUser(null);
            });
		} else {
            setIsLoading(false);
			setErrorP(true);
        }

		return () => {};
	}, []);

	return { user, isErrorP, isLoading } as const;
};
