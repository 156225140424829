import { Switch, Route } from 'react-router-dom';
import AuthProvider from './contexts/auth.context';
import EditUser from './pages/auth/EditUser';
import Home from './pages/auth/Home';
import Models from './pages/auth/Models';
import PredictionsBatch from './pages/auth/PredictionsBatch';
import PredictionsBatchResult from './pages/auth/PredictionsBatchResult';
import PredictionsResult from './pages/auth/PredictionsResult';
import PredictionsSingle from './pages/auth/PredictionsSingle';
import SettingsPassword from './pages/auth/SettingsPassword';
import SettingsProfile from './pages/auth/SettingsProfile';
import Users from './pages/auth/Users';
import ViewModel from './pages/auth/ViewModel';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
import SignUp from './pages/SignUp';
import ProtectedRoute from './components/ProtectedRoute';
import ModelProvider from './contexts/model.context';
import PermissionProvider from './contexts/permissions.context';
import BadRequest from './pages/BadRequest';
import SinglePredictionForm from './components/predictions/SinglePredictionForm';
import BatchPredictionForm from './components/predictions/BatchPredictionForm';
import GraphPlot from './pages/auth/GraphPlot';

function App() {
	return (
		<AuthProvider>
			<ModelProvider>
				<PermissionProvider>
					<Switch>
						<Route path='/' exact component={Login} />
						<Route path='/signup' exact component={SignUp} />
						<Route
							path='/forgot-password'
							exact
							component={ForgotPassword}
						/>
						<Route
							path='/password-reset'
							exact
							component={PasswordReset}
						/>
						<ProtectedRoute path='/home' exact component={Home} />
						<ProtectedRoute path='/users' exact component={Users} />
						<ProtectedRoute
							path='/users/edituser/:id'
							exact
							component={EditUser}
						/>
						<ProtectedRoute
							path='/models'
							exact
							component={Models}
						/>
						<ProtectedRoute
							path='/models/viewmodel/:id'
							exact
							component={ViewModel}
						/>
						<ProtectedRoute
							path='/predictions'
							exact
							component={PredictionsSingle}
						/>
						<ProtectedRoute
							path='/predictions/single/:id'
							exact
							component={SinglePredictionForm}
						/>
						<ProtectedRoute
							path='/predictions/batchform/:id'
							exact
							component={BatchPredictionForm}
						/>
						<ProtectedRoute
							path='/graph/result'
							exact
							component={GraphPlot}
						/>
						<ProtectedRoute
							path='/predictions/result'
							exact
							component={PredictionsResult}
						/>
						<ProtectedRoute
							path='/predictions/batch'
							exact
							component={PredictionsBatch}
						/>
						<ProtectedRoute
							path='/predictions/batch/results'
							exact
							component={PredictionsBatchResult}
						/>
						<ProtectedRoute
							path='/settings/profile'
							exact
							component={SettingsProfile}
						/>
						<ProtectedRoute
							path='/settings/password'
							exact
							component={SettingsPassword}
						/>
						<Route component={BadRequest}/>
					</Switch>
				</PermissionProvider>
			</ModelProvider>
		</AuthProvider>
	);
}

export default App;
