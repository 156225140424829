import Header from '../components/onboarding/Header';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import { IPasswordReset } from '../interfaces/auth.interface';
import { motion } from 'framer-motion';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { useRef, useState } from 'react';
import { Auth } from '../api/api';
import { usePasswordReset } from '../hooks/usePasswordReset';
import BrokenLink from '../components/BrokenLink';
import MainLoader from '../components/MainLoader';
import Notification from '../components/Notification';
import MiniLoader from '../components/MiniLoader';
import creditNetLogo from '../assets/images/creditnet_logo.png';

const FormInputTarget = styled.div`
  input {
    background: none;
  }
`;

const Variant = {
  initial1: {
    y: 300,
    opacity: 0,
  },
  animate1: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  initial2: {
    x: 300,
    opacity: 0,
  },
  animate2: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: 'easeOut',
    },
  },
};

const PasswordReset = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const ref = useRef<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user, isErrorP, isLoading } = usePasswordReset(params['?id']);

  const onFinish = (values: IPasswordReset) => {
    let creds = {
      password: values.password,
      newPassword: values.newPassword,
    };
    setLoading(true);
    if (window.navigator.onLine) {
      let cred = {
        token: params.token,
        id: params['?id'],
        password: creds.password,
      };

      Auth.resetPassword(cred)
        .then((resp) => {
          if (resp.status === 'success') {
            setLoading(false);
            ref.current.resetFields();
            Notification({
              type: 'success',
              message: 'Success!',
              description:
                'Password reset successfully! Please login with your new credential',
            });
            history.push('/');
          } else {
            setLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description:
                "Oops! We could'nt reset your password. Please contact your admin or try again",
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description:
              "Oops! We could'nt reset your password. Please contact your admin or try again",
          });
        });
    } else {
      setLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  if (Object.keys(params).length === 0) {
    return <Redirect to='/' />;
  }

  return (
    <div className='p-8 lg:p-16 mb-8 min-h-screen bg-brand-white relative'>
      <Header />
      <Link to='/'>
        <img
          src={creditNetLogo}
          alt='creditNet Logo'
          className='w-[227px] mb-20'
        />
      </Link>
      {isErrorP ? (
        <BrokenLink
          desc='Please contact your admin or try resetting your password
					again'
        />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        <section className='flex flex-col lg:flex-row h-full'>
          <div className='flex flex-col justify-center w-full mb-6 lg:mb-0'>
            <motion.div
              className='w-full'
              variants={Variant}
              initial='initial1'
              animate='animate1'
            >
              <h1 className='text-3xl lg:text-5xl font-bold text-brand-black-primary mb-2'>
                Almost there
              </h1>
              <p className='text-base font-normal text-brand-black-secondary w-full lg:w-9/12'>
                Please change your password to continue
              </p>
              <div className='sm:hidden lg:block mt-10'>
                <p className='text-sm text-brand-black-secondary font-semibold'>
                  Powered by{' '}
                  <a
                    href='https://insidaticsai.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-brand-blue-primary hover:text-brand-blue-primary-900'
                  >
                    InsidaticsAI
                  </a>
                </p>
              </div>
            </motion.div>
          </div>
          <div className='flex flex-col justify-evenly items-center w-full h-full'>
            <motion.div
              className='w-full lg:w-10/12 h-full'
              variants={Variant}
              initial='initial2'
              animate='animate2'
            >
              <h3 className='text-lg font-bold text-brand-black-secondary mb-7'>
                Hi {user && `${user.firstname} ${user.lastname}`}
              </h3>
              <Form
                layout='vertical'
                name='basic'
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                ref={ref}
              >
                <Form.Item
                  label='Password'
                  name='password'
                  className='mb-4'
                  rules={[
                    {
                      required: true,
                      message:
                        'Password must be minumum of 8 characters, contains capital letter, small letter, number and special character',
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    },
                  ]}
                  hasFeedback
                >
                  <FormInputTarget>
                    <Input.Password
                      className='w-full bg-transparent border h-10 px-3 rounded-md'
                      placeholder='enter your password'
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormInputTarget>
                </Form.Item>
                <Form.Item
                  label='Confirm Password'
                  name='newPassword'
                  className='mb-4'
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <FormInputTarget>
                    <Input.Password
                      className='w-full bg-transparent border h-10 px-3 rounded-md'
                      placeholder='enter your password'
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormInputTarget>
                </Form.Item>
                <Form.Item>
                  <button
                    className='w-full mt-10 h-10 bg-brand-blue-primary text-brand-white font-bold hover:bg-brand-blue-primary-900 rounded-md'
                    type='submit'
                    disabled={loading}
                  >
                    {loading ? <MiniLoader /> : 'Change my password'}
                  </button>
                </Form.Item>
              </Form>
            </motion.div>
            <div className='absolute bottom-0 sm:block lg:hidden'>
              <p className='text-sm text-brand-black-secondary font-semibold text-center self-stretch'>
                Powered by{' '}
                <a
                  href='https://insidaticsai.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-brand-blue-primary hover:text-brand-blue-primary-900'
                >
                  InsidaticsAI
                </a>
              </p>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PasswordReset;
