import Layout from '../../components/Layout';
import TrainedIcon from '../../assets/svg/TrainedIcon';
import TotalPredIcon from '../../assets/svg/TotalPredIcon';
import { Table, Tooltip } from 'antd';
import TableWrapper from '../../components/helper/TableWrapper';
import { useDashboard } from '../../hooks/useDashboard';
import MainLoader from '../../components/MainLoader';
import ErrorMsg from '../../components/ErrorMsg';
import { Link } from 'react-router-dom';

const Home = () => {
  const { details, isError, isLoading } = useDashboard();

  const columns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      render: (id: number) => (
        <>
          <span>{id}</span>
        </>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => (
        <Tooltip placement='top' title='Status of trained model'>
          {value === 'completed' ? (
            <span className='font-normal capitalize px-4 py-2 lg:px-6 text-brand-success-900 bg-brand-success-400 rounded-full'>
              {value}
            </span>
          ) : (
            <span className='font-normal capitalize px-4 py-2 lg:px-6 text-brand-warning-900 bg-brand-warning-400 rounded-full'>
              {value}
            </span>
          )}
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (id: number) => (
        <>
          <Link to={`models/viewmodel/${id}`}>
            <span className='font-semibold text-brand-blue-primary  py-2 transition ease-out duration-500 hover:text-brand-blue-primary-900 inline-block mr-8 lg:mr-16'>
              View
            </span>
          </Link>
        </>
      ),
    },
  ];

  const data = details?.data.models?.slice(0, 5).map((detail, i) => ({
    key: detail.id,
    sn: i + 1,
    title: detail.model_desc,
    created_on: detail.created_on.substring(0, 16),
    status: 'completed',
    action: detail.public_id,
  }));

  return (
    <Layout title='home'>
      {isError ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        <>
          <section className='flex mb-10 lg:mb-20 flex-col lg:flex-row justify-between'>
            <div className='h-32 py-4 px-8 mb-7 lg:mb-0 w-full lg:w-[48%] bg-brand-white overview-bg card-shadow'>
              <div className='h-10 mb-4 w-10 rounded-full bg-brand-blue-primary-400 flex items-center justify-center'>
                <TrainedIcon />
              </div>
              <div className='flex items-center'>
                <h1 className='text-[2rem] font-bold text-brand-black-primary mr-3'>
                  {details?.data.model_count}
                </h1>
                <p className='text-base  text-brand-black-secondary'>
                  Trained Model(s)
                </p>
              </div>
            </div>
            <div className='h-32 py-4 px-8 w-full lg:w-[48%] bg-brand-white overview-bg card-shadow'>
              <div className='h-10 mb-4 w-10 rounded-full bg-brand-success-400 flex items-center justify-center'>
                <TotalPredIcon />
              </div>
              <div className='flex items-center'>
                <h1 className='text-[2rem] font-bold text-brand-black-primary mr-3'>
                  {details?.data.prediction_count}
                </h1>
                <p className='text-base text-brand-black-secondary'>
                  Total Prediction(s)
                </p>
              </div>
            </div>
          </section>
          <section className='card-shadow bg-brand-white w-full p-8 mb-8'>
            <h3 className='text-lg capitalize font-bold text-brand-black-primary mb-4'>
              recent models
            </h3>
            {details?.data.models === null ||
            details?.data.models.length === 0 ? (
              <h1 className='text-brand-black-secondary text-base text-center'>
                Ooops looks like you don't have any model
              </h1>
            ) : (
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className=''
                />
              </TableWrapper>
            )}
          </section>
        </>
      )}
    </Layout>
  );
};

export default Home;
