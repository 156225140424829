import * as React from 'react';

function AddIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={25}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M12 5.5v14M5 12.5h14'
				stroke='#FCFCFC'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default AddIcon;
