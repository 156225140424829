import * as React from "react";

function DangerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8 4.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.212 3.212 0 01-1.743.516H5.298C3.477 21 2 19.47 2 17.581c0-.639.173-1.264.498-1.807L9.2 4.614c.962-1.604 2.996-2.095 4.543-1.097.428.276.79.651 1.057 1.096zm-2.22.84a1.077 1.077 0 00-1.514.364L4.365 16.98a1.17 1.17 0 00-.166.602c0 .63.492 1.14 1.1 1.14H18.7c.206 0 .407-.06.581-.172a1.164 1.164 0 00.353-1.57L12.933 5.817a1.12 1.12 0 00-.352-.365h-.001zM12 17a1 1 0 110-2 1 1 0 010 2zm0-9a1 1 0 011 1v4a1 1 0 01-2 0V9a1 1 0 011-1z"
        fill="#7F1D1D"
      />
    </svg>
  );
}

export default DangerIcon;