import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<header className='mb-24'>
			<Link to='/'>
				<div className='w-[227px]'/>
			</Link>
		</header>
	);
};

export default Header;
