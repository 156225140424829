import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { deletePredDetails } from '../api/helper';
import creditNetLogo from '../assets/images/creditnet_logo.png';
import CloseIcon from '../assets/svg/CloseIcon';
import HomeIcon from '../assets/svg/HomeIcon';
import ModelIcon from '../assets/svg/ModelIcon';
import PredictionIcon from '../assets/svg/PredictionIcon';
import UsersIcon from '../assets/svg/UsersIcon';
import { ISidebar } from '../interfaces/ui.interface';

const SidebarWrap = styled.div`
  box-shadow: 0px 4px 4px rgba(29, 78, 216, 0.25);

  .blur-bg {
    background: rgba(29, 78, 216, 0.3);
    backdrop-filter: blur(2px);
  }

  .svg-focus {
    color: #fcfcfc;
    background: #1d4ed8;
    transition: all 0.5s ease-in-out;
    svg > path {
      stroke: #fcfcfc;
    }

    &:hover {
      background: #1e3a8a;
    }
  }

  .svg-focus2 {
    color: #fcfcfc;
    background: #1d4ed8;
    transition: all 0.5s ease-in-out;
    svg > path {
      stroke: #fcfcfc;
      fill: #fcfcfc;
    }

    &:hover {
      background: #1e3a8a;
    }
  }
`;

const Sidebar: FC<ISidebar> = ({ isModalOpen, handleModal }) => {
  return (
    <SidebarWrap className='bg-brand-white flex flex-col items-center w-48'>
      <nav
        className={`lg:block w-48 bg-brand-white z-20 h-screen max-h-screen fixed left-0 pl-4 pr-4 ${
          isModalOpen ? 'block' : 'hidden'
        }`}
      >
        <div className='flex justify-center w-full mb-10 py-5 border-b'>
          <img src={creditNetLogo} alt='creditNet Logo' className='w-32' />
        </div>
        <NavLink
          to='/home'
          activeClassName='svg-focus'
          className='flex items-center py-2 mb-2 bg pl-4 rounded-md lg:mb-5 text-brand-black-secondary hover:text-brand-black-secondary hover:bg-brand-blue-primary-400'
          onClick={() => deletePredDetails()}
        >
          <HomeIcon className='mr-4' />
          <span className='font-semibold capitalize text-sm '>home</span>
        </NavLink>
        <NavLink
          to='/models'
          activeClassName='svg-focus'
          className='flex items-center py-2 mb-2 bg pl-4 rounded-md lg:mb-5 text-brand-black-secondary hover:text-brand-black-secondary hover:bg-brand-blue-primary-400'
          onClick={() => deletePredDetails()}
        >
          <ModelIcon className='mr-4' />
          <span className='font-semibold capitalize text-sm '>models</span>
        </NavLink>
        <NavLink
          to='/predictions'
          activeClassName='svg-focus'
          className='flex items-center py-2 mb-2 bg pl-4 rounded-md lg:mb-5 text-brand-black-secondary hover:text-brand-black-secondary hover:bg-brand-blue-primary-400'
          onClick={() => deletePredDetails()}
        >
          <PredictionIcon className='mr-4' />
          <span className='font-semibold capitalize text-sm '>predictions</span>
        </NavLink>
        <NavLink
          to='/users'
          activeClassName='svg-focus'
          className='flex items-center py-2 mb-2 bg pl-4 rounded-md lg:mb-5 text-brand-black-secondary hover:text-brand-black-secondary hover:bg-brand-blue-primary-400'
          onClick={() => deletePredDetails()}
        >
          <UsersIcon className='mr-4' />
          <span className='font-semibold capitalize text-sm '>users</span>
        </NavLink>
      </nav>
      <div
        className={`h-screen w-screen top-0 z-10 left-0 lg:hidden blur-bg ${
          isModalOpen ? 'fixed' : 'hidden'
        }`}
        onClick={handleModal}
      >
        <div className='mt-3 float-right mr-3'>
          <div
            role='button'
            className='cursor-pointer w-6 h-6 rounded-full flex items-center justify-center bg-brand-white'
            onClick={handleModal}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    </SidebarWrap>
  );
};

export default Sidebar;
