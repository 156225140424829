import { Form, Input } from 'antd';
import Layout from '../../components/Layout';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DragUploadCSV from './DragUploadCSV';
import ShortButton from './ShortButton';
import { useGetModelDetails } from '../../hooks/useGetModelDetails';
import ErrorMsg from '../ErrorMsg';
import MainLoader from '../MainLoader';
import { PostAPIPlain } from '../../hooks/helperHook';
import { singlePredDetails, tokenState } from '../../api/helper';
import Notification from '../Notification';
import MiniLoader from '../MiniLoader';
import { RouteComponentProps } from 'react-router-dom';
import { PredictionResultTab } from '../PredictionResultTab';

interface IForm {
  setIsModelSelected: (selected: boolean) => void;
}

const SinglePredictionForm = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState<string[] | null>();
  const [fieldDetails, setFieldDetails] = useState<any>();
  const [prefillDetails, setPrefillDetails] = useState<any>(); //use to get saved form item from local
  const history = useHistory();
  const id = match?.params.id;
  const { details, isError, isLoading } = useGetModelDetails(id);
  const predDetails: any = JSON.parse(singlePredDetails()!);

  useEffect(() => {
    details && setFeatures([...Object.keys(details.all_feature_kwarg)]);
    details && setFieldDetails(details.all_feature_kwarg);
    predDetails && setPrefillDetails([...Object.keys(predDetails)]);
    return () => {};
  }, [details]);


  const onFinish = (values: any) => {
    let formData: any = {};
    features?.forEach((element: any) => {
      if (fieldDetails[element].type === 'numeric') {
        formData[element] = [parseInt(values[element])];
      } else {
        formData[element] = [values[element]];
      }
    });
    let creds = {
      model_url: details.model_loc,
      inference_kwarg: formData,
      model_id: details.id,
    };

    localStorage.setItem('singlePredDetails', JSON.stringify(formData));

    setLoading(true);
    if (window.navigator.onLine) {
      PostAPIPlain('inference/json', creds, tokenState)
        .then((resp) => {
          if (resp.data.status === 'success') {
            setLoading(false);
            Notification({
              type: 'success',
              message: 'Success!',
              description: 'Inference successful',
            });
            history.push('/predictions/result', [
              resp.data,
              details.model_desc,
              details.public_id,
              details.model_loc,
            ]);
          } else {
            setLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description: resp.data.message,
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description: e.response.data.message,
          });
        });
    } else {
      setLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const checkFieldType = (type: string): any => {
    if (type === 'categorical') {
      return 'text';
    } else if (type === 'numeric') {
      return 'number';
    } else if (type === 'freetext') {
      return 'text';
    } else {
      return 'date';
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Layout title='predictions'>
      {isError ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        details && (
          <div>
            <Link
              to='/predictions'
              className='text-sm font-bold inline-block text-brand-blue-primary hover:text-brand-blue-primary-900 border-b border-brand-blue-primary mb-8'
            >
              Change Model
            </Link>
            <PredictionResultTab modelId={id} />
            <section className='mb-10'>
              <h3 className='text-brand-black-primary text-lg font-bold'>
                Model -{' '}
                <span className='text-sm text-brand-black-secondary'>
                  {details.model_desc}
                </span>
              </h3>
              <p className='text-xs font-semibold text-brand-black-secondary w-10/12'>
                This model requires the following columns and data type
              </p>
            </section>
            <section className='flex flex-col lg:flex-row lg:items-start mt-16'>
              <div className='w-full lg:w-[45%]'>
                <Form
                  layout='vertical'
                  name='basic'
                  initialValues={
                    predDetails === null
                      ? undefined
                      : prefillDetails?.reduce(
                          (prev: any, curr: any) => ({
                            ...prev,
                            [curr]: predDetails[curr].toString(),
                          }),
                          {}
                        )
                  }
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className='w-full'
                >
                  <div className='grid grid-cols-2'>
                    {features?.map((field, i) => (
                      <Form.Item
                        label={
                          fieldDetails[field].user_friendly_name
                            ? fieldDetails[field].user_friendly_name
                            : field
                        }
                        name={field}
                        className='mb-6 w-[90%]'
                        key={i}
                        rules={[
                          {
                            required: true,
                            message: `Please input your ${
                              fieldDetails[field].user_friendly_name
                                ? fieldDetails[field].user_friendly_name
                                : field
                            }!`,
                            type: 'string',
                          },
                        ]}
                      >
                        <Input
                          className='w-full border h-10 px-3 rounded-md'
                          placeholder={`enter your ${
                            fieldDetails[field].user_friendly_name
                              ? fieldDetails[field].user_friendly_name
                              : field
                          }`}
                          type={checkFieldType(fieldDetails[field].type)}
                        />
                      </Form.Item>
                    ))}
                  </div>
                  <Form.Item>
                    <ShortButton
                      text={loading ? <MiniLoader /> : 'Send Request'}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className='flex items-center justify-center w-full lg:w-[10%]'>
                <h1 className='uppercase text-lg font-bold text-brand-blue-primary'>
                  or
                </h1>
              </div>
              <div className='flex items-center justify-center w-full lg:w-[45%]'>
                <DragUploadCSV
                  model_url={details.model_loc}
                  details={details}
                  is_batch={false}
                />
              </div>
            </section>
            {/* <PredictedColumns /> */}
          </div>
        )
      )}
    </Layout>
  );
};

export default SinglePredictionForm;