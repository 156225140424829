import React from 'react'

const ErrorMsg = () => {
    return (
		<div className='h-screen flex justify-center items-center'>
			<h1 className='text-center text-2xl text-brand-danger-700'>
				Ooops! Unable to get data. Please try again or contact
				your administrator.
			</h1>
		</div>
	);
}

export default ErrorMsg
