import * as React from 'react';

function CSVWarningIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={12} cy={12} r={11.5} fill='#FEF2F2' stroke='#7F1D1D' />
			<path
				d='M11.061 14.14c.105.705 1.005.705 1.11 0l1.053-7.072c.193-2.757-3.411-2.757-3.216 0l1.053 7.072zM11.616 18.645c.893 0 1.616-.721 1.616-1.611s-.723-1.612-1.616-1.612c-.893 0-1.616.722-1.616 1.612 0 .89.723 1.611 1.616 1.611z'
				fill='#7F1D1D'
			/>
		</svg>
	);
}

export default CSVWarningIcon;
