import Header from '../components/onboarding/Header';
import { Form, Input } from 'antd';
import { IForgotPassword } from '../interfaces/auth.interface';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import MiniLoader from '../components/MiniLoader';
import { Auth } from '../api/api';
import Notification from '../components/Notification';
import creditNetLogo from '../assets/images/creditnet_logo.png';
import { Link } from 'react-router-dom';

const Variant = {
  initial1: {
    y: 300,
    opacity: 0,
  },
  animate1: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  initial2: {
    x: 300,
    opacity: 0,
  },
  animate2: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: 'easeOut',
    },
  },
};

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<any>();
  const onFinish = (values: IForgotPassword) => {
    let creds = {
      email: values.email,
    };
    setIsLoading(true);
    if (window.navigator.onLine) {
      Auth.forgotPassword(creds)
        .then((resp) => {
          if (resp.status === 'success') {
            setIsLoading(false);
            ref.current.resetFields();
            Notification({
              type: 'success',
              message: 'Success!',
              description:
                'Password link sent successfully! Please check your email to effect changes',
            });
          } else {
            setIsLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description:
                "Oops! We could'nt reset your password. It seems you supplied a wrong email, please contact your admin",
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description:
              "Oops! We could'nt reset your password. It seems you supplied a wrong email, please contact your admin",
          });
        });
    } else {
      setIsLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };
  return (
    <div className='p-8 lg:p-16 mb-8 min-h-screen bg-brand-white relative'>
      <Header />
      <Link to='/'>
        <img
          src={creditNetLogo}
          alt='creditNet Logo'
          className='w-[227px] mb-20'
        />
      </Link>
      <section className='flex flex-col lg:flex-row h-full'>
        <div className='flex flex-col justify-center w-full mb-6 lg:mb-0'>
          <motion.div
            className='w-full'
            variants={Variant}
            initial='initial1'
            animate='animate1'
          >
            <h1 className='text-3xl lg:text-5xl font-bold text-brand-black-primary mb-2'>
              Forgot password
            </h1>
            <p className='text-base font-normal text-brand-black-secondary w-full lg:w-9/12'>
              Enter your email address & we'll send you a link to reset <br />
              your password.
            </p>
            <div className='sm:hidden lg:block mt-10'>
              <p className='text-sm text-brand-black-secondary font-semibold'>
                Powered by{' '}
                <a
                  href='https://insidaticsai.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-brand-blue-primary hover:text-brand-blue-primary-900'
                >
                  InsidaticsAI
                </a>
              </p>
            </div>
          </motion.div>
        </div>
        <div className='flex flex-col justify-evenly items-center w-full h-full'>
          <motion.div
            className='w-full lg:w-10/12 h-full'
            variants={Variant}
            initial='initial2'
            animate='animate2'
          >
            <Form
              layout='vertical'
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              ref={ref}
            >
              <Form.Item
                label='Email'
                name='email'
                className='mb-6'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email',
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                ]}
              >
                <Input
                  className='w-full bg-transparent border h-10 px-3 rounded-md'
                  placeholder='enter your email'
                />
              </Form.Item>
              <Form.Item>
                <button
                  className='w-full h-10 bg-brand-blue-primary text-brand-white font-bold hover:bg-brand-blue-primary-900  rounded-md'
                  type='submit'
                  disabled={isLoading}
                >
                  {isLoading ? <MiniLoader /> : 'Send me a link'}
                </button>
              </Form.Item>
            </Form>
          </motion.div>
          <div className='absolute bottom-0 sm:block lg:hidden'>
            <p className='text-sm text-brand-black-secondary font-semibold text-center self-stretch'>
              Powered by{' '}
              <a
                href='https://insidaticsai.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='text-brand-blue-primary hover:text-brand-blue-primary-900'
              >
                InsidaticsAI
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
