import Header from '../components/onboarding/Header';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import { ISignUp } from '../interfaces/auth.interface';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import queryString from 'querystring';
import { useRef, useState } from 'react';
import BrokenLink from '../components/BrokenLink';
import MainLoader from '../components/MainLoader';
import { useGetLicenseInfo } from '../hooks/useGetLicenseInfo';
import Notification from '../components/Notification';
import { Auth } from '../api/api';
import MiniLoader from '../components/MiniLoader';
import creditNetLogo from '../assets/images/creditnet_logo.png';

const FormInputTarget = styled.div`
  input {
    background: none;
  }
`;

const Variant = {
  initial1: {
    y: 300,
    opacity: 0,
  },
  animate1: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  initial2: {
    x: 300,
    opacity: 0,
  },
  animate2: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: 'easeOut',
    },
  },
};

const SignUp = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const ref = useRef<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user, isErrorP, isLoading } = useGetLicenseInfo({
    c_id: params?.c_id?.toString(),
    u_id: params['?u_id']?.toString(),
  });

  const onFinish = (values: ISignUp) => {
    let creds = {
      email: values.email,
      token: values.token,
      password: values.password,
      newPassword: values.newPassword,
    };
    setLoading(true);
    if (window.navigator.onLine) {
      let cred = {
        token: params.token,
        id: params['?u_id'],
        password: creds.password,
      };

      Auth.resetPassword(cred)
        .then((resp) => {
          if (resp.status === 'success') {
            setLoading(false);
            ref.current.resetFields();
            Notification({
              type: 'success',
              message: 'Success!',
              description:
                'Registration completed successfully! Please login with your new credential',
            });
            history.push('/');
          } else {
            setLoading(false);
            Notification({
              type: 'error',
              message: 'Error!',
              description:
                "Oops! We could'nt reset your password. Please contact your admin or try again",
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          Notification({
            type: 'error',
            message: 'Error!',
            description:
              "Oops! We could'nt reset your password. Please contact your admin or try again",
          });
        });
    } else {
      setLoading(false);
      Notification({
        type: 'error',
        message: 'Error!',
        description: 'Please check your internet connection!',
      });
    }
  };

  const onFinishFailed = (errorInfo: {}) => {
    // console.log('Failed:', errorInfo);
  };

  if (Object.keys(params).length === 0) {
    return <Redirect to='/' />;
  }

  return (
    <div className='p-8 lg:p-16 mb-8 min-h-screen bg-brand-white relative'>
      <Header />
      {isErrorP ? (
        <BrokenLink
          desc='Please contact your admin or try resetting your password
					again'
        />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        user && (
          <section className='flex flex-col lg:flex-row h-full'>
            <div className='flex flex-col justify-between w-full mb-6 lg:mb-0'>
              <motion.div
                className='w-full'
                variants={Variant}
                initial='initial1'
                animate='animate1'
              >
                <Link to='/'>
                  <img
                    src={creditNetLogo}
                    alt='creditNet Logo'
                    className='w-[227px] mb-20'
                  />
                </Link>
                <h1 className='text-3xl lg:text-5xl font-bold text-brand-black-primary mb-2'>
                  Let’s get you started
                </h1>
                <p className='text-base font-normal text-brand-black-secondary w-full lg:w-9/12'>
                  In just a few steps, you can get insights into your business
                  model and make quick decisions.
                </p>
              </motion.div>
              <div className='sm:hidden lg:block mt-10'>
                <p className='text-sm text-brand-black-secondary font-semibold'>
                  Powered by{' '}
                  <a
                    href='https://insidaticsai.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-brand-blue-primary hover:text-brand-blue-primary-900'
                  >
                    InsidaticsAI
                  </a>
                </p>
              </div>
            </div>
            <div className='flex flex-col justify-evenly items-center w-full h-full'>
              <motion.div
                className='w-full lg:w-10/12 h-full'
                variants={Variant}
                initial='initial2'
                animate='animate2'
              >
                <Form
                  layout='vertical'
                  name='basic'
                  initialValues={{
                    email: user && user?.email,
                    token: '***********************',
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  ref={ref}
                >
                  <section className='mb-10'>
                    <h3 className='text-lg font-bold text-brand-black-secondary mb-[15px]'>
                      Hi {user && `${user.firstname} ${user.lastname}`}
                    </h3>
                    <p className='text-brand-black-secondary font-normal text-sm'>
                      Please enter and confirm your password to get started
                    </p>
                  </section>
                  <Form.Item
                    label='Email'
                    name='email'
                    className='mb-4'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                        type: 'email',
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      },
                    ]}
                  >
                    <Input
                      className='w-full bg-transparent border h-10 px-3 rounded-md'
                      placeholder='enter your email'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='Access Token'
                    name='token'
                    className='mb-4'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your token!',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input
                      className='w-full bg-transparent border h-10 px-3 rounded-md'
                      placeholder='enter your token'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='Password'
                    name='password'
                    className='mb-4'
                    rules={[
                      {
                        required: true,
                        message:
                          'Password must be minumum of 8 characters, contains capital letter, small letter, number and special character',
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      },
                    ]}
                    hasFeedback
                  >
                    <FormInputTarget>
                      <Input.Password
                        className='w-full bg-transparent border h-10 px-3 rounded-md'
                        placeholder='enter your password'
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </FormInputTarget>
                  </Form.Item>
                  <Form.Item
                    label='Confirm Password'
                    name='newPassword'
                    className='mb-4'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <FormInputTarget>
                      <Input.Password
                        className='w-full bg-transparent border h-10 px-3 rounded-md'
                        placeholder='enter your password'
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </FormInputTarget>
                  </Form.Item>
                  <Form.Item>
                    <button
                      className='w-full mt-10 h-10 capitalize bg-brand-blue-primary text-brand-white font-bold hover:bg-brand-blue-primary-900 rounded-md'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? <MiniLoader /> : 'complete registration'}
                    </button>
                  </Form.Item>
                </Form>
              </motion.div>
              <div className='absolute bottom-0 sm:block lg:hidden'>
                <p className='text-sm text-brand-black-secondary font-semibold text-center self-stretch'>
                  Powered by{' '}
                  <a
                    href='https://insidaticsai.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-brand-blue-primary hover:text-brand-blue-primary-900'
                  >
                    InsidaticsAI
                  </a>
                </p>
              </div>
            </div>
          </section>
        )
      )}
    </div>
  );
};

export default SignUp;
