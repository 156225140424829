import * as React from 'react';

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M3 6h18'
				stroke='#5A5A7D'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20 6a1 1 0 10-2 0h2zM6 6a1 1 0 00-2 0h2zm1 0a1 1 0 002 0H7zm8 0a1 1 0 102 0h-2zm3 0v14h2V6h-2zm0 14a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1 1H7v2h10v-2zM7 21a1 1 0 01-1-1H4a3 3 0 003 3v-2zm-1-1V6H4v14h2zM9 6V4H7v2h2zm0-2a1 1 0 011-1V1a3 3 0 00-3 3h2zm1-1h4V1h-4v2zm4 0a1 1 0 011 1h2a3 3 0 00-3-3v2zm1 1v2h2V4h-2z'
				fill='#5A5A7D'
			/>
			<path
				d='M10 11v6M14 11v6'
				stroke='#5A5A7D'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default DeleteIcon;
