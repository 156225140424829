import axios from 'axios';
import { useEffect, useState } from 'react';
import { URL } from '../api/api';
import { tokenState } from '../api/helper';
import { IResponseSingleUser, IUser } from '../interfaces/responses.interface';
import { GetAPI } from './helperHook';

export const useGetSingleUser = (id: string) => {
	const [isError, setError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [user, setUser] = useState<IUser | null>();

	useEffect(() => {
		setIsLoading(true);
		if (window.navigator.onLine && tokenState) {
			GetAPI<IResponseSingleUser>(`user/${id}`, tokenState)
				.then((resp) => {
					if (resp.data.status === 'success') {
						setError(false);
						setIsLoading(false);
						setUser(resp.data.data);
					}
				})
				.catch((e) => {
					setError(true);
					setIsLoading(false);
					setUser(null);
				});
		} else {
			setError(true);
		}

		return () => {};
	}, []);

	return { isError, isLoading, user } as const;
};
