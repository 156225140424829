import * as React from 'react';

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={25}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				clipRule='evenodd'
				d='M4 4.5h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-12c0-1.1.9-2 2-2z'
				stroke='#FCFCFC'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M22 6.5l-10 7-10-7'
				stroke='#FCFCFC'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default MailIcon;
