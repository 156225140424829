import { useContext,} from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthContext } from '../contexts/auth.context';

const ProtectedRoute= ({ ...routeProps }: RouteProps) => {
    const {isAuthenticated} = useContext(AuthContext);
    if (isAuthenticated()) {
		return <Route {...routeProps} />;
	} else {
		return <Redirect to={{ pathname: '/' }} />;
	}

};

export default ProtectedRoute;
