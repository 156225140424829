import * as React from 'react';

function TotalPredIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={19}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M17.675 9.55a1.331 1.331 0 11-2.663.001 1.331 1.331 0 012.663 0zM16.67 15.878a1.332 1.332 0 11-2.664-.005 1.332 1.332 0 012.664.005zM15.468 4.321a1.331 1.331 0 100-2.662 1.331 1.331 0 000 2.662zM3.552 11.12a1.777 1.777 0 11-3.554-.005 1.777 1.777 0 013.554.005zM10.588 9.523a1.776 1.776 0 11-3.553-.003 1.776 1.776 0 013.553.003z'
				fill='#064E3B'
			/>
			<path
				d='M8.737 9.172L8.65 8.89l-6.077 1.87.087.283 6.077-1.871zM9.664 8.227l-.102-.376 4.325-3.988.102.376-4.325 3.988zM13.72 14.912l-4.174-4.641.122-.306 4.174 4.642-.123.305zM14.596 9.337H8.68v.296h5.917v-.296z'
				fill='#064E3B'
			/>
			<path
				d='M15.468 4.978a1.99 1.99 0 100-3.978 1.99 1.99 0 000 3.978zM16.344 11.513a1.99 1.99 0 100-3.978 1.99 1.99 0 000 3.978zM15.339 17.866a1.989 1.989 0 100-3.978 1.989 1.989 0 000 3.978z'
				fill='#064E3B'
				stroke='#064E3B'
				strokeWidth={1.5}
				strokeMiterlimit={10}
			/>
		</svg>
	);
}

export default TotalPredIcon;
