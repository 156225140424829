import { ICreateUser } from '../interfaces/auth.interface';
import { Form, Input, Select } from 'antd';
import LongButton from './helper/LongButton';
import { FC, useContext, useRef, useState } from 'react';
import { ICreateUserModal } from '../interfaces/ui.interface';
import {CreateUserAPI} from '../hooks/helperHook'
import Notification from './Notification';
import MiniLoader from './MiniLoader';
import { PermissionContext } from '../contexts/permissions.context';
import ErrorMsg from './ErrorMsg';
import MainLoader from './MainLoader';
import { tokenState } from '../api/helper';

const { Option } = Select;

const CreateUser: FC<ICreateUserModal> = ({ setModal, setUsers, users }) => {
	const [isError, setError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const ref = useRef<any>();
	const { permission, isErrorP, isLoadingP } = useContext(PermissionContext);

	const onFinish = (values: ICreateUser) => {
		let creds = {
			firstname: values.firstname,
			lastname: values.lastname,
			email: values.email,
			page_permissions: values.page_permissions,
			inference_model_perm: values.inference_model_perm,
		};

		if (window.navigator.onLine) {
			try {
				setIsLoading(true);
				CreateUserAPI<ICreateUser>(creds, tokenState)
					.then((resp) => {
						if (resp.status === 'success') {
							setIsLoading(false);
							setError(false);
							Notification({
								type: 'success',
								message: 'Success!',
								description: 'User created Successfully!',
							});
							const data = resp.data as any;
							setUsers([data, ...users!]);
							ref.current.resetFields();
						} else{
							setIsLoading(false);
							setError(true);
							Notification({
								type: 'error',
								message: 'Error!',
								description: resp.message,
							});
						}
					})
					.catch((e: any) => {
						setIsLoading(false);
						setError(true);
						Notification({
							type: 'error',
							message: 'Error!',
							description: e.response.data.message,
						});
					});
			} catch (e: any) {
				setIsLoading(false);
				setError(true);
				Notification({
					type: 'error',
					message: 'Error!',
					description: e.response.data.message,
				});
			}
		} else {
			setError(true);
			Notification({
				type: 'error',
				message: 'Error!',
				description: 'Please check your connection!',
			});
		}
	};

	const onFinishFailed = (errorInfo: {}) => {
	};

	const data = [
		{ key: '1', value: 'can edit' },
		{ key: '2', value: 'can save' },
		{ key: '3', value: 'can model' },
	];
	return (
		<>
			{isErrorP ? (
				<ErrorMsg />
			) : isLoadingP ? (
				<MainLoader />
			) : (
				<Form
					layout='vertical'
					name='basic'
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					ref={ref}
				>
					<h2 className='capitalize text-2xl text-brand-black-secondary font-bold mb-7'>
						add user
					</h2>
					<div className='flex justify-between'>
						<Form.Item
							label='First Name'
							name='firstname'
							className='mb-6 w-[48%]'
							rules={[
								{
									required: true,
									message: 'Please input your firstname!',
									type: 'string',
								},
							]}
						>
							<Input
								className='w-full border h-10 px-3 rounded-md'
								placeholder='enter your firstname'
							/>
						</Form.Item>
						<Form.Item
							label='Last Name'
							name='lastname'
							className='mb-6 w-[48%]'
							rules={[
								{
									required: true,
									message: 'Please input your lastname!',
									type: 'string',
								},
							]}
						>
							<Input
								className='w-full border h-10 px-3 rounded-md'
								placeholder='enter your lastname'
							/>
						</Form.Item>
					</div>
					<Form.Item
						label='Email'
						name='email'
						className='mb-6'
						rules={[
							{
								required: true,
								message: 'Please input your email!',
								type: 'email',
								pattern:
									/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							},
						]}
					>
						<Input
							className='w-full border h-10 px-3 rounded-md'
							placeholder='enter your email'
						/>
					</Form.Item>
					<Form.Item
						label='Permissions'
						name='page_permissions'
						className='mb-6'
						rules={[
							{
								required: true,
								message: 'Please select permission(s)!',
								type: 'array',
							},
						]}
					>
						<Select
							mode='multiple'
							placeholder='select permission(s)'
							className='w-full'
							size='large'
							showArrow={true}
						>
							{permission &&
								permission!.map((data) => (
									<Option
										key={data.public_id}
										value={data.public_id}
									>
										{data.desc}
									</Option>
								))}
						</Select>
					</Form.Item>
					<Form.Item
						label='Models'
						name='inference_model_perm'
						className='mb-6'
						rules={[
							{
								required: true,
								message: 'Please select model(s)!',
								type: 'array',
							},
						]}
					>
						<Select
							mode='multiple'
							placeholder='select model(s)'
							className='w-full'
							size='large'
							showArrow={true}
						>
							{data.map((data) => (
								<Option key={data.key} value={data.key}>
									{data.value}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item>
						<LongButton
							text={isLoading ? <MiniLoader /> : 'Create User'}
							disabled={isLoading}
						/>
					</Form.Item>
				</Form>
			)}
		</>
	);
};

export default CreateUser;
