import { LoadingOutlined } from '@ant-design/icons';

const MainLoader = () => {
	return (
		<div className='h-screen flex justify-center items-center'>
			<div>
				<LoadingOutlined
					className='text-7xl text-brand-blue-primary mb-5'
					spin
				/>
				<p className='text-brand-black-secondary text-sm'>
					Please wait .....
				</p>
			</div>
		</div>
	);
};

export default MainLoader;
