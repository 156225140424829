import { FC } from 'react';
import { IButton } from '../../interfaces/ui.interface';

const FillButton: FC<IButton> = ({ children, text, handleClick }) => {
	return (
		<button
			onClick={handleClick}
			className='w-32 block rounded-md px-3 py-2 bg-brand-blue-primary hover:bg-brand-blue-primary-900 transition-all ease-out duration-500'
		>
			<span className='flex items-center'>
				{children}{' '}
				<span className='text-sm font-bold text-white ml-3 capitalize'>
					{text}
				</span>
			</span>
		</button>
	);
};

export default FillButton;
