import axios from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { URL } from '../api/api';
import { tokenState } from '../api/helper';
import { AuthContext } from './auth.context';
import { IPermissions } from './types.interface';

const defaultPerm: IPermissions = {
	permission: null,
	isErrorP: false,
	isLoadingP: false,
};

export const PermissionContext = createContext<IPermissions>(defaultPerm);

const PermissionProvider: FC = ({ children }) => {
	const [permission, setPermission] = useState(defaultPerm);
	const { isAuthenticated } = useContext(AuthContext);

	useEffect(() => {
		setPermission({ ...permission, isErrorP: false, isLoadingP: true });
		if (window.navigator.onLine) {
			axios
				.get(`${URL}permissions/`, {
					timeout: 15000,
					headers: {
						Authorization: `Bearer ${tokenState() && tokenState()}`,
					},
				})
				.then((resp) => {
					if (resp.data.status === 'success') {
						setPermission({
							...permission,
							permission: resp.data.data,
							isErrorP: false,
							isLoadingP: false,
						});
					}
				});
		} else {
			setPermission({ ...permission, isErrorP: true, isLoadingP: false });
		}
		return () => {};
	}, [isAuthenticated()]);

	return (
		<PermissionContext.Provider value={permission}>
			{children}
		</PermissionContext.Provider>
	);
};

export default PermissionProvider;
