import { Link, useLocation } from 'react-router-dom';

export const SettingTab = () => {
	const location = useLocation();
	return (
		<div className='flex border-b-2 mb-10'>
			<Link
				to='/settings/profile'
				className={`text-base capitalize mr-8 pb-4 ${
					location.pathname === '/settings/profile'
						? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
						: 'text-brand-black-secondary hover:text-brand-black-primary'
				} `}
			>
				profile settings
			</Link>
			<Link
				to='/settings/password'
				className={`text-base capitalize mr-8 pb-4 ${
					location.pathname === '/settings/password'
						? 'font-bold border-b-4 border-brand-blue-primary text-brand-blue-primary hover:text-brand-blue-primary-900'
						: 'text-brand-black-secondary hover:text-brand-black-primary'
				} `}
			>
				change password
			</Link>
		</div>
	);
};
