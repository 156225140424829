import { FC } from 'react';
import { ILongButton } from '../../interfaces/ui.interface';

const ShortButton: FC<ILongButton> = ({ text }) => {
	return (
		<button className='text-brand-white font-bold rounded-md px-3 py-2 bg-brand-blue-primary hover:bg-brand-blue-primary-900 transition-all ease-out duration-500'>
			{text}
		</button>
	);
};

export default ShortButton;
