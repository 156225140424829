import { Link, RouteComponentProps } from 'react-router-dom';
import Layout from '../../components/Layout';
import InfoIcon from '../../assets/svg/InfoIcon';
import { useGetModelDetails } from '../../hooks/useGetModelDetails';
import ErrorMsg from '../../components/ErrorMsg';
import MainLoader from '../../components/MainLoader';
import FeaturesList from '../../components/FeaturesList';
import AlgorithmnIcon from '../../assets/svg/AlgorithmnIcon';
import { useState } from 'react';

const ViewModel = ({ match }: RouteComponentProps<{ id: string }>) => {
  const id = match.params.id;
  const { details, isError, isLoading } = useGetModelDetails(id);
  const [state, setState] = useState({
    categorical: { state: false, value: 'categorical features' },
    numerical: { state: false, value: 'numerical features' },
    date: { state: false, value: 'date features' },
    freeText: { state: false, value: 'freeText features' },
  });
  

  return (
    <Layout title='view model'>
      {isError ? (
        <ErrorMsg />
      ) : isLoading ? (
        <MainLoader />
      ) : (
        details && (
          <>
            <section className='flex justify-between items-center mb-7'>
              <div className='text-sm text-brand-black-secondary capitalize font-bold'>
                <Link
                  to='/models'
                  className='text-brand-blue-primary hover:text-brand-blue-primary-900 underline hover:underline'
                >
                  view models
                </Link>
                <span> / {details.model_desc}</span>
              </div>
              <Link
                to={`/predictions/single/${id}`}
                className='text-brand-white font-bold rounded-md px-3 py-2 bg-brand-black-secondary hover:bg-brand-black-primary transition-all hover:text-brand-white ease-out duration-500'
              >
                Make Prediction
              </Link>
            </section>
            <div className='flex justify-between flex-col lg:flex-row'>
              <section className='card-shadow p-4 bg-brand-white mb-7 w-full lg:w-[48%]'>
                <div className='flex items-center w-full  border-b border-brand-black-disabled pb-3 mb-3'>
                  <InfoIcon className='mr-3' />
                  <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                    model information
                  </p>
                </div>
                <div className=''>
                  <div className='col-span-3 flex justify-end mb-3 lg:hidden'>
                    <div className='font-normal capitalize px-4 py-2 lg:px-6 text-brand-success-900 bg-brand-success-400 rounded-full'>
                      {details.status}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 lg:grid-cols-2 gap-y-7'>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        name
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                        {details.model_desc}
                      </p>
                    </div>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        model ID
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                        {details.public_id}
                      </p>
                    </div>

                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        created on
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                        {details.created_on}
                      </p>
                    </div>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        Model White Paper
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                        <Link
                          to={details.white_paper_loc}
                          className='text-brand-blue-primary hover:text-brand-blue-primary-900 underline hover:underline'
                        >
                          Download
                        </Link>
                      </p>
                    </div>
                    <div className='hidden lg:block'>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        status
                      </p>
                      <div className='font-normal w-28 h-7 capitalize flex items-center justify-center text-brand-success-900 bg-brand-success-400 rounded-full'>
                        {details.status}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className='card-shadow p-4 bg-brand-white mb-7 w-full lg:w-[48%]'>
                <div className='flex items-center w-full  border-b border-brand-black-disabled pb-3 mb-3'>
                  <AlgorithmnIcon className='mr-3' />
                  <p className='text-base mb-0 font-bold text-brand-black-primary capitalize'>
                    Algorithm
                  </p>
                </div>
                <div className=''>
                  <div className='grid grid-cols-2 lg:grid-cols-2 gap-y-7'>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        Algorithm type
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                        {details.model_type}
                      </p>
                    </div>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        Performance (PR-AUC)
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                      {details.pr_auc.toFixed(3)}
                      </p>
                    </div>
                    <div className=''>
                      <p className='text-sm font-normal text-brand-black-secondary capitalize mb-3'>
                        Performance (ROC-AUC)
                      </p>
                      <p className='text-sm font-bold text-brand-black-primary capitalize'>
                      {details.pr_auc.toFixed(3)}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {details.features.categorical.length > 0 && (
              <FeaturesList
                title='categorical features'
                column={details.features.categorical}
                features={details.feature_user_friendly_names}
                state={state}
                featureImportance={details.feature_importance}
                setState={setState}
              />
            )}
            {details.features.numeric.length > 0 && (
              <FeaturesList
                title='numerical features'
                column={details.features.numeric}
                features={details.feature_user_friendly_names}
                state={state}
                featureImportance={details.feature_importance}
                setState={setState}
              />
            )}
            {details.features.date.length > 0 && (
              <FeaturesList
                title='date features'
                column={details.features.date}
                features={details.feature_user_friendly_names}
                state={state}
                featureImportance={details.feature_importance}
                setState={setState}
              />
            )}
            {details.features.free_text.length > 0 && (
              <FeaturesList
                title='freeText features'
                column={details.features.free_text}
                features={details.feature_user_friendly_names}
                state={state}
                featureImportance={details.feature_importance}
                setState={setState}
              />
            )}
          </>
        )
      )}
    </Layout>
  );
};

export default ViewModel;
