import * as React from 'react';

function TrainedIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={17}
			height={19}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M16.595 13.913l-4.834-7.765a1.526 1.526 0 01-.21-.762V1.294h.514a.642.642 0 00.647-.647.642.642 0 00-.647-.647H4.966a.642.642 0 00-.647.647c0 .362.285.647.647.647h.514v4.092c0 .267-.076.533-.21.762L.418 13.913a2.708 2.708 0 00-.076 2.76c.495.875 1.37 1.408 2.38 1.408h11.533a2.678 2.678 0 002.38-1.408c.513-.876.475-1.904-.039-2.76zM10.257 1.294v4.092c0 .514.133 1.009.419 1.447l1.313 2.131a7.166 7.166 0 01-2.284-.456c-1.39-.533-2.779-.686-4.11-.457l.76-1.218a2.78 2.78 0 00.42-1.447V1.294h3.482zm5.272 14.77c-.266.456-.723.742-1.256.742H2.739a1.419 1.419 0 01-1.256-.742 1.402 1.402 0 01.038-1.466l3.064-4.93c1.428-.608 3.027-.59 4.663.039.99.38 1.96.552 2.912.552.21 0 .42-.02.61-.038l2.721 4.377a1.4 1.4 0 01.038 1.466z'
				fill='#1E3A8A'
			/>
		</svg>
	);
}

export default TrainedIcon;
